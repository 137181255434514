.property-table {
  tr {
    border-bottom: 1px solid rgba($black, 0.05);

    &:last-child {
      border-bottom: none;
    }
  }

  td {
    padding: 5px 0;
  }

  .label {
    width: 100%;

    @media (min-width: 768px) {
      width: 40%;
    }
  }
  .value {
    width: 100%;

    @media (min-width: 768px) {
      width: 60%;
    }
  }
}
//$primaryColor: #FEE370;
$primaryColor: #1D8197;
$primaryColorDark: darken($primaryColor, 10%);
$primaryColorLight: lighten($primaryColor, 10%);

$secondaryColor: #dbb489;
//$secondaryColor: #cffaff;
//$secondaryColor: #91e3e0;
$secondaryColorDark: darken($secondaryColor, 10%);
$secondaryColorLight: lighten($secondaryColor, 10%);

$tertiaryColor: #dbb489;

$highlightColor: rgb(249, 252, 175, 0.43);

$black: #181818;
$blackLight: lighten($black, 30%);
$gray: #414141;
$grayMid: #b2b2b2;
$grayLight: rgb(238, 238, 238);
$white: #fff;
$whiteDarken: darken($white, 30%);

$green: rgb(43, 136, 0);
$yellow: #FEE370;
$yellowLight: #fffee3;
$beigeLight: rgba(209, 185, 158, 0.2);
$turkisLight: rgba(114, 134, 137, 0.3);
$orange: #fea970;
$red: #c74366;
.promo-banner {
  min-height: 65px;
  @media screen and (min-width: 768px) {
    min-height: 40px;
  }

  *, :hover {
    color: inherit;
  }
}

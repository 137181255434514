.img-comp-container,
.img-comp-container *{
  box-sizing: border-box;
}

.img-comp-container {
  position: relative;
  width: 100%;
}

.img-comp-img {
  position: absolute;
  width: 100%;
  height: auto;
  overflow: hidden;
}

.img-comp-img img {
  display: block;
  vertical-align: middle;
  max-width: revert;
}

.img-comp-slider {
  position: absolute;
  z-index: 9;
  cursor: ew-resize;
  /*set the appearance of the slider:*/
  width: 40px;
  height: 40px;
  background-image: url("https://cdn.perfecthair.ch/media/vector/9a/64/cd/perfecthair-logo-picturemark-rgb.svg");
  background-size: contain;
  /*opacity: 0.7;*/
  border-radius: 50%;
}
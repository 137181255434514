.blog {
  &-tile {
    margin: 0 0 35px 0;

    @media (min-width: 768px) {
      width: 33%;
    }

    @media (min-width: 1200px) {
      width: 50%;
    }

    .text {
      margin-top: -5px;
    }
  }

  &-listing {
    &-tile {
      margin-bottom: 20px;

      @media (min-width: 768px) {
        width: 32%;
      }

      .sub-title {
        @media (min-width: 768px) {
          height: 450px;
        }

        @media (min-width: 992px) {
          height: 350px;
        }

        @media (min-width: 1200px) {
          height: 290px;
        }
      }
    }
  }

  &-content {
    p {
      margin-bottom: 0;
    }

    ul {
      margin: 20px 0 30px;
    }

    h2, h3 {
      margin: 40px 0 0;
    }

    h4, h5, h6 {
      margin: 20px 0 10px;
    }
  }

  &-cta {
    border-bottom: 1px solid $black;
    border-color: $primaryColor;
    position: relative;
    width: 100%;

    .button-cta {
      border-top: 1px solid $black;
      border-left: 1px solid $black;
      border-right: 1px solid $black;
      border-top-right-radius: 8px;
      border-top-left-radius: 8px;
      border-color: $primaryColor;
      background-color: lighten($primaryColor, 15%);

      &:hover {
        background-color: $primaryColor;
      }

      //&-center {
      //  border-top: 1px solid $black;
      //  border-radius: 5px;
      //  width: auto;
      //}
      //
      //&-left {
      //  border-left: 1px solid $black;
      //  height: calc(100% + 23px);
      //  transform: rotate(45deg);
      //  position: relative;
      //  left: -24px;
      //  top: -11px;
      //}
      //
      //&-right {
      //  border-right: 1px solid $black;
      //  height: calc(100% + 23px);
      //  transform: rotate(-45deg);
      //  position: relative;
      //  right: -24px;
      //  top: -11px;
      //}
    }
  }
}

.fade {
  &-enter, &-appear {
    animation: fadeIn 1, fadeOut 1s;
    -webkit-animation: fadeIn 1, fadeOut 1s;
    -moz-animation: fadeIn 1, fadeOut 1s;
    -o-animation: fadeIn 1, fadeOut 1s;
    -ms-animation: fadeIn 1, fadeOut 1s;
  }

  &-enter-active, &-appear-active {
    transition: all 500ms;
  }

  &-exit {
    animation: fadeOut 1s;
    -webkit-animation: fadeOut 1s;
    -moz-animation: fadeOut 1s;
    -o-animation: fadeOut 1s;
    -ms-animation: fadeOut 1s;
  }

  &-exit-active {
    transition: all 500ms;
  }
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@-moz-keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@-webkit-keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@-o-keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@-ms-keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes fadeOut {
  100% { opacity: 1; }
  0% { opacity: 0; }
}

@-moz-keyframes fadeOut {
  100% { opacity: 1; }
  0% { opacity: 0; }
}

@-webkit-keyframes fadeOut {
  100% { opacity: 1; }
  0% { opacity: 0; }
}

@-o-keyframes fadeOut {
  100% { opacity: 1; }
  0% { opacity: 0; }
}

@-ms-keyframes fadeOut {
  100% { opacity: 1; }
  0% { opacity: 0; }
}
.cc-nb-okagree,
.cc-cp-foot-save
{
  background-color: $primaryColor !important;

  &:hover {
    background-color: $primaryColor !important;
    color: $white;
  }
}

.termsfeed-com---pc-dialog input[type=checkbox].cc-custom-checkbox:checked+label:before {
  background: $primaryColor !important;
}

.cc-pc-container, #termsfeed-com---nb {
  border-radius: 15px;
}

.cc-pc-head {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.cc-cp-foot {
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

.termsfeed-com---nb-simple {
  max-width: 100% !important;
  width: 100% !important;
  z-index: 1000 !important;
}

.termsfeed-com---nb .cc-nb-title {
  font-size: 16px !important;
  margin-bottom: 0.3rem !important;
}

.termsfeed-com---nb .cc-nb-text {
  font-size: 12px !important;
}

.termsfeed-com---nb .cc-nb-main-container {
  padding: 1rem !important;
}
.emotion {
  &-text-image {
    &:first-child {
      div {
        font-weight: bold;
        margin-top: 30px;
      }
    }

    div {
      color: $white;
    }
  }

  &-container {
    &.brand-listing {
      width: auto;
    }

    ul {
      margin: 25px 0;
    }
  }

  &-bg-color {
    margin-top: 0;

    > div {
      padding: 30px;
      //background-color: lighten($black, 6);
      background-color: #fff;
      height: 100%;
      border-radius: 8px;
      border: 1px solid rgba(0, 0, 0, 0.1);

      * {
        color: $black;
      }

      [role=button] {
        color: $black;
      }
    }
  }

  &-grid {
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }

    .col-md-6 {
      .emotion-image {
        max-height: 350px;
      }
    }
  }

  &-flex-grid {
    > div {
      margin: 0 7px;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &-image {
    min-height: 400px;

    @media (min-width: 768px) {
      min-height: auto;
    }
  }

  &-id {
    &-3 {
      &.emotion {
        &-flex {
          &-width {
            &-2 {
              @media (min-width: 768px) {
                width: 19%
              }
            }

            &-4 {
              @media (min-width: 768px) {
                width: 39%
              }
            }

            &-6 {
              @media (min-width: 768px) {
                width: 59%
              }
            }

            &-8 {
              @media (min-width: 768px) {
                width: 79%
              }
            }

            &-10 {
              @media (min-width: 768px) {
                width: 100%
              }
            }
          }
        }
      }
    }
  }
}

.emotions-grid {
  grid-template-columns: repeat(1, 1fr);

  @media (min-width: 768px) {
    display: grid;
    grid-template-rows: auto 1fr;
    grid-row-gap: 25px;
    grid-auto-rows: minmax(min-content, max-content);
  }

  > div {
    height: inherit;
  }
}

.cta {
  &-overlay {
    .bg-white {
      > span, > i {
        color: $black;
      }
    }
  }
}
// for eliminating stray <br> tags from WYSIWYG editor in promo banners
.no-br {
  p {
    margin: 0;
  }

  br {
    display: none;
  }
}

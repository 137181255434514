.nav {
    &-header {
        text-transform: uppercase;

        a {
            display: block;

            &:hover, &.active {
                span {
                    border-bottom: 3px solid $black;
                }
            }

            span {
                padding-bottom: 7px;
            }
        }

        .link-secondary {
            &:hover {
                .nav-header-overlay {
                    display: block;
                }
            }
        }

        li {
            &:hover {
                .nav-sub {
                    display: block;
                }
            }
        }
    }

    &-sub {
        //display: none;
        position: absolute;
        top: 37px;
        height: auto;
        width: 100%;
        left: 0;
        background-color: $white;
        z-index: 9999;
        padding: 30px 0;
        text-transform: none;
        -webkit-box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgba(0, 0, 0, 0);
        box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgba(0, 0, 0, 0);
    }

    &-side {
        margin-left: 0 !important;

        > li {
            border-bottom: 1px solid rgba(0,0,0,0.1);
            padding: 10px 0;

            &:last-child {
                border-bottom: none;
            }

            &:not(.open) {
                ul {
                    display: none;
                }
            }

            &.open {
                li {
                    //&:not(.active) {
                    //    > ul {
                    //        display: none;
                    //    }
                    //}
                }
            }

            > ul {
                &:not(:first-of-type) {
                    margin-top: 10px;
                }

                &:first-of-type {
                    margin-top: -5px;
                }

                ul {
                    margin-bottom: 15px;
                }
            }
        }

        ul {
            margin: 5px 0 0 30px;

            li {
                a {
                    display: block;
                    padding: 7px 0;

                }
            }
        }

        a {
            display: block;
            padding: 10px 0;
            word-wrap: break-word;
            word-break: break-word;

            &.active {
                font-weight: bold;
            }
        }

        li {
            &.active {
                ul {
                    display: block;

                    li {
                        ul {
                            display: block;
                        }
                    }
                }
            }

            a {
                &:not(.active) + ul {
                    display: none !important;
                }
            }
        }
    }
}

.link-secondary {
    color: $black;
}

.breadcrumb-item + .breadcrumb-item::before {
    margin-top: 0;
}

.breadcrumb-divider {
    --bs-breadcrumb-divider: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='currentColor'/%3E%3C/svg%3E");
}

.nav-tabs {
    .nav-link {
        border: none;
        padding-bottom: 20px;
        color: $black;

        &.active {
            border-bottom: 3px solid $black;
            background-color: transparent;
        }
    }
}

.page-link {
    color: $black;

    &:hover {
        color: $blackLight;
    }

    &.active {
        font-weight: bold;
    }
}

.pagination {
    .page-item {
        .page-link {
            border: none;
            padding: 10px 15px;

            &:hover {
                color: $secondaryColor;
                background-color: transparent;
            }

            i {
                &:before {
                    position: relative;
                    top: 4px;
                }
            }
        }
    }
}

.w-80 {
    width: 80% !important;
}

.new-navigation {
    grid-template-columns: 2fr 2fr 3fr 3fr 3fr;
    grid-auto-rows: 1fr;
    grid-gap: 2rem !important;
}

.special-banner-container {
    width: 90vw;
}

.menu-new-blog-image{
    border-radius: 4px;
}

.special-banner,
.new-blog-div{
    border-radius: 10px;
}

.new-blog-text{
    font-weight: bold;
}

.new-menu-product-slider{
    &[data-per-page="1"] {
          max-width: 180px;
      }
    &[data-per-page="2"] {
        max-width: 90vw;
    }

    .product-tile {

        a {
            &:hover, &.active {
                span {
                    border-bottom: none !important;
                }
            }
        }

        img {
            height: 150px;
        }

        .variant-container,
        .actions,
        .badge-container {
            display: none !important;
        }

    }

}
.brand-image-link {
    padding-bottom: 1.3rem;
}
.special-menu-more{
    text-decoration: underline solid black;
    margin-top: auto !important;
}
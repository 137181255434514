.offcanvas {
    z-index: 500;

    &-bg {
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.6);
        position: absolute;
        top: 0;
        left: 0;
        z-index: 600;
    }

    &-nav-sub {
        position: fixed;
        top: 0;
        left: 0;
        background-color: $white;
        z-index: 9999;
        width: 100%;
        height: 100%;
        text-transform: none;
        -webkit-box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgba(0, 0, 0, 0);
        box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgba(0, 0, 0, 0);
    }

    &-right-md-b {
        position: fixed;
        width: 100%;
        height: 100%;
        right: 0;
        top: 0;
        background-color: $white;
        z-index: 550;
        display: none;
        -webkit-box-shadow: -10px 0px 13px -7px #000000, 10px 0px 13px -7px #000000, 5px 5px 15px 5px rgba(0, 0, 0, 0);
        box-shadow: -10px 0px 13px -7px #000000, 10px 0px 13px -7px #000000, 5px 5px 15px 5px rgba(0, 0, 0, 0);

        @media (min-width: 768px) {
            position: fixed;
            width: 430px;
            height: 100%;
        }
    }

    &-cart {
        position: fixed;
        width: 100%;
        height: 100%;
        //max-height: 700px;
        right: 0;
        top: 0;
        background-color: $white;
        z-index: 6500;
        -webkit-box-shadow: -10px 0px 13px -7px #000000, 10px 0px 13px -7px #000000, 5px 5px 15px 5px rgba(0, 0, 0, 0);
        box-shadow: -10px 0px 13px -7px #000000, 10px 0px 13px -7px #000000, 5px 5px 15px 5px rgba(0, 0, 0, 0);
        overflow: hidden auto;

        @media (min-width: 768px) {
            position: fixed;
            width: 430px;
            height: 100%;
            border-top-left-radius: 20px;
            border-bottom-left-radius: 20px;
        }

        .loading-bg {
            @media (min-width: 768px) {
                width: 430px;
                height: 100%;
            }
        }
    }

    &-right {
        position: fixed;
        width: 100%;
        height: 100%;
        right: 0;
        top: 0;
        background-color: $white;
        z-index: 1000;
        -webkit-box-shadow: -10px 0px 13px -7px #000000, 10px 0px 13px -7px #000000, 5px 5px 15px 5px rgba(0, 0, 0, 0);
        box-shadow: -10px 0px 13px -7px #000000, 10px 0px 13px -7px #000000, 5px 5px 15px 5px rgba(0, 0, 0, 0);

        @media (min-width: 1200px) {
            display: none;
            border-top-left-radius: 20px;
            border-bottom-left-radius: 20px;
        }

        &_scroll {
            position: absolute;
            width: 100%;
            height: 100%;
            overflow: auto;
        }
    }

    &-filter {
        [data-filter="close"], [data-filter="back"] {

        }

        [data-filter="back"] {
            display: none;
        }
    }
}

.mui-offcanvas {
    overflow-x: hidden;
    min-width: 100vw;

    @media(min-width: 768px) {
        min-width: 100%;
        width: 480px;
    }
}
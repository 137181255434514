.compare-grid {
  position: relative;
  display: grid;
  grid-auto-rows: max-content;
  overflow: visible;
  grid-template-columns: repeat(var(--columns), 60%);
  align-items: stretch;
  gap: 1rem;

  > div {
    position: relative;

    //&:first-child {
    //  position: sticky;
    //  left: 0;
    //  background-color: #fff;
    //  z-index: 5;
    //
    //  > div {
    //    &:before {
    //      content: '';
    //      background-color: #dee2e6;
    //      position: absolute;
    //      right: 0;
    //      bottom: 1rem;
    //      width: 1px;
    //      height: 100%;
    //      display: block;
    //    }
    //  }
    //}

    &:after {
      content: '';
      background-color: #dee2e6;
      position: absolute;
      left: -0.5rem;
      bottom: 0;
      width: 100%;
      height: 1px;
    }

    &:before {
      content: '';
      background-color: #dee2e6;
      position: absolute;
      left: -1rem;
      bottom: 1rem;
      width: 1px;
      height: 100%;
    }

    //&:not(:nth-child(2)) {
    //  &:before {
    //    content: '';
    //    background-color: #dee2e6;
    //    position: absolute;
    //    left: -1rem;
    //    bottom: 1rem;
    //    width: 1px;
    //    height: 100%;
    //  }
    //}
  }

  @media (min-width: 1024px) {
    grid-template-columns: repeat(var(--columns), 290px);
  }

  > div {
    &:last-child {
      border-right: none;
    }
  }
}
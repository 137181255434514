@font-face {
  font-family: 'Poppins';
  src: url('../../fonts/poppins/Poppins-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins Bold';
  src: url('../../fonts/poppins/Poppins-Bold.ttf') format('truetype');
  font-display: swap;
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins Light';
  src: url('../../fonts/poppins/Poppins-Light.ttf') format('truetype');
  font-display: swap;
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins ExtraBold';
  src: url('../../fonts/poppins/Poppins-ExtraBold.ttf') format('truetype');
  font-display: swap;
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins BoldItalic';
  src: url('../../fonts/poppins/Poppins-BoldItalic.ttf') format('truetype');
  font-display: swap;
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Poppins LightItalic';
  src: url('../../fonts/poppins/Poppins-LightItalic.ttf') format('truetype');
  font-display: swap;
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Poppins UltraBold';
  src: url('../../fonts/poppins/Poppins-ExtraBold.ttf') format('truetype');
  font-display: swap;
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins ExtraLight';
  src: url('../../fonts/poppins/Poppins-Light.ttf') format('truetype');
  font-display: swap;
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins Medium';
  src: url('../../fonts/poppins/Poppins-Medium.ttf') format('truetype');
  font-display: swap;
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins ExtraBoldItalic';
  src: url('../../fonts/poppins/Poppins-ExtraBoldItalic.ttf') format('truetype');
  font-display: swap;
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'Poppins MediumItalic';
  src: url('../../fonts/poppins/Poppins-MediumItalic.ttf') format('truetype');
  font-display: swap;
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Poppins UltraBoldItalic';
  src: url('../../fonts/poppins/Poppins-ExtraBoldItalic.ttf') format('truetype');
  font-display: swap;
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Poppins Demi';
  src: url('../../fonts/poppins/Poppins-Bold.ttf') format('truetype');
  font-display: swap;
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins Demi Bold Oblique';
  src: url('../../fonts/poppins/Poppins-BoldItalic.ttf') format('truetype');
  font-display: swap;
  font-weight: 600;
  font-style: italic;
}


.form-control {
    font-size: 14px;

    &[type="text"], &[type="number"] {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
    }

    &:focus {
        box-shadow: none;
        border-color: $black;
    }

    &::placeholder {
        font-size: 0.87rem !important;
    }
}

.form-control-plaintext {
    &:focus-visible {
        outline: none;
    }
}

.input-text-icon {
    position: relative;

    .btn {
        position: absolute;
        right: 0;
        height: 38px !important;
        width: 45px;
        top: -3px;
    }
}

select {
    option {
        margin-top: 5px;
        margin-bottom: 5px;
    }
}

.form-select {
    visibility: hidden;
}

.form-dropdown {
    font-size: 14px;

    .dropdown-toggle {
        font-weight: normal;
    }

    .btn {
        padding: 8px 0.75rem;
        font-size: 14px;
        text-align: left;
        color: #6c757d;
    }

    .dropdown {
        width: 100%;
    }

    ul {
        li {
            a {
                font-size: 14px;
                font-weight: normal;
            }
        }
    }
}

.dropdown {
    position: relative;
    width: 100%;

    //@media (min-width: 768px) {
    //  width: 100%;
    //}

    &-wrap {
        position: relative;
    }

    &-toggle {
        padding-left: 14px;
        position: relative;
        font-weight: bold;
        z-index: 900;

        &:after {
            margin: 8px 0 0 10px;
        }

        &.no-toggle {
            &:after {
                display: none;
            }
        }
    }

    .dropdown-menu {
        width: 100%;
        min-width: auto;

        &.show {
            z-index: 1200;
            min-width: 98px;
        }

        li {
            &:hover {
                background-color: $grayLight;
            }

            div {
                padding: 10px 0;
                //text-align: center;
                margin: 0 auto;
                width: 10px;

                &:hover {
                    background-color: transparent;
                }
            }
        }
    }

    &-item {
        font-weight: bold;
    }
}

.detail-quantity {
    height: 48px !important;
}

.dropdown-input {
    position: absolute;
    visibility: hidden;
}

.rating-selector {
    .fa-star {
        &:hover {
            cursor: pointer;
        }
    }
}

.rating-text {
    padding-top: 16px;
    display: flex;
    flex-direction: row;
}

form {
    .btn {
        width: 100%;

        @media (min-width: 768px) {
            width: 30%;
        }

        &-sm {
            @media (max-width: 767px) {
                width: 60px !important;
                height: 60px;
                text-align: center;

                i {
                    position: relative;
                    top: unset;
                    right: unset;
                    bottom: unset;
                    font-size: 1.3rem;
                }
            }
        }
    }
}

.form-floating {
    > textarea.form-control {
        height: 80px;
        padding: 0.75rem;

        &:not(:placeholder-shown) {
            padding-top: 0.75rem;
        }
    }
}

.billing-address-form, .shipping-address-form {
    display: none;
}

.billing-address-form, .shipping-address-form, .delivery-address {
    @media (min-width: 768px) {
        min-height: 380px;
    }
}

.payment-method-form, .shipping-method-form {
    position: relative;

    @media (min-width: 768px) {
        min-height: 420px;
    }
}

.list-sub-label {
    margin-left: 33px;
}

.dropdown {
    &-item {
        &.active, &:active, &:hover, &:focus {
            //background-color: $beigeLight;
            background-color: $turkisLight;
            color: $black;
        }
    }
}

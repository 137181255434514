@font-face {
  font-family: 'pfhag';
  src: url('../../fonts/icons/pfhag.eot');
  src: url('../../fonts/icons/pfhag.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/icons/pfhag.woff') format('woff'),
  url('../../fonts/icons/pfhag.ttf') format('truetype'),
  url('../../fonts/icons/pfhag.svg#fontname') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

[class^="icon-pfhag-"]::before, [class*=" icon-pfhag-"]::before {
  font-family: "pfhag";
  font-style: normal;
  font-weight: normal;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  margin-left: .2em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-display: swap;
}

$icon-pfhag-arrow-right: '\e800';
$icon-pfhag-arrow-left: '\e801';
$icon-pfhag-arrow-bold-right: '\e802';
$icon-pfhag-arrow-bold-left: '\e803';
$icon-pfhag-arrow-down: '\e804';
$icon-pfhag-arrow-up: '\e805';
$icon-pfhag-x: '\e806';
$icon-pfhag-search: '\e807';
$icon-pfhag-warenkorb2: '\e808';
$icon-pfhag-chat: '\e809';
$icon-pfhag-kreditkarte: '\e80a';
$icon-pfhag-rechnung: '\e80b';
$icon-pfhag-tipp: '\e80c';
$icon-pfhag-neu: '\e80d';
$icon-pfhag-bar: '\e80e';
$icon-pfhag-auswahl: '\e80f';
$icon-pfhag-preise: '\e810';
$icon-pfhag-clock: '\e811';
$icon-pfhag-pen: '\e812';
$icon-pfhag-star: '\e813';
$icon-pfhag-reload: '\e814';
$icon-pfhag-vergleichen: '\e815';
$icon-pfhag-discount: '\e816';
$icon-pfhag-phone: '\e817';
$icon-pfhag-phone2: '\e818';
$icon-pfhag-menu: '\e819';
$icon-pfhag-texting: '\e81a';
$icon-pfhag-hacken-kreis: '\e81b';
$icon-pfhag-questionmark: '\e81c';
$icon-pfhag-hacken-shield: '\e81d';
$icon-pfhag-anmelden: '\e829';
$icon-pfhag-bestellung-ueberpruefen: '\e82b';
$icon-pfhag-call: '\e82d';
$icon-pfhag-grosse-auswahl: '\e82e';
$icon-pfhag-haeckchen: '\e82f';
$icon-pfhag-herz: '\e830';
$icon-pfhag-instagram: '\e831';
$icon-pfhag-klarna: '\e832';
$icon-pfhag-konto: '\e833';
$icon-pfhag-lieferbar: '\e834';
$icon-pfhag-maestro: '\e835';
$icon-pfhag-magazin: '\e836';
$icon-pfhag-mail: '\e837';
$icon-pfhag-marken: '\e838';
$icon-pfhag-mastercard: '\e839';
$icon-pfhag-nicht-lieferbar: '\e83a';
$icon-pfhag-paypal: '\e83b';
$icon-pfhag-postfinance: '\e83c';
$icon-pfhag-rechnung-1: '\e83d';
$icon-pfhag-sameday-lieferung: '\e83e';
$icon-pfhag-social-facebook: '\e83f';
$icon-pfhag-social-youtube: '\e840';
$icon-pfhag-social-instagram: '\e841';
$icon-pfhag-social-linkedin: '\e842';
$icon-pfhag-social-pinterest: '\e843';
$icon-pfhag-sofortueberweisung: '\e844';
$icon-pfhag-suchen: '\e845';
$icon-pfhag-twint: '\e846';
$icon-pfhag-visa: '\e847';
$icon-pfhag-vorkasse: '\e848';
$icon-pfhag-warenkorb: '\e849';
$icon-pfhag-whatsapp: '\e84a';
$icon-pfhag-wunschliste: '\e84b';
$icon-pfhag-wunschliste-aktiv: '\e84c';
$icon-pfhag-sichere-bestellung-klein: '\e84e';
$icon-pfhag-kostenlose-beratung-klein: '\e84f';
$icon-pfhag-coiffeurprodukte-klein: '\e850';
$icon-pfhag-gratis-internet-klein: '\e851';
$icon-pfhag-online-terminbuchung-klein: '\e852';
$icon-pfhag-grosse-auswahl-klein: '\e853';
$icon-pfhag-attraktive-preise-klein: '\e854';
$icon-pfhag-haarstyling: '\e855';
$icon-pfhag-schnelle-lieferung-klein: '\e856';
$icon-pfhag-hautpflege: '\e857';
$icon-pfhag-naegel: '\e858';
$icon-pfhag-make-up: '\e859';
$icon-pfhag-haarpflege: '\e85a';
$icon-pfhag-haare-faerben: '\e85b';
$icon-pfhag-tools: '\e85c';
$icon-pfhag-duefte: '\e85d';
$icon-pfhag-adressen: '\e85f';
$icon-pfhag-bearbeiten: '\e861';
$icon-pfhag-tiktok: '\e862';
$icon-pfhag-lieblingsprodukte: '\e863';
$icon-pfhag-email: '\e864';
$icon-pfhag-insel: '\e866';
$icon-pfhag-tasche: '\e867';
$icon-pfhag-ueberraschung: '\e868';
$icon-pfhag-nogo: '\e869';
$icon-pfhag-mitarbeiter: '\e86a';
$icon-pfhag-store-locator: '\e86b';
$icon-pfhag-stern: '\e86c';
$icon-pfhag-abmelden: '\e86d';
$icon-pfhag-zeit: '\e86e';
$icon-pfhag-zahlungsart: '\e86f';
$icon-pfhag-uebersicht: '\e870';
$icon-pfhag-bei-perfecthair-seit: '\e871';
$icon-pfhag-amex: '\e872';
$icon-pfhag-prepayment: '\e873';
$icon-pfhag-paiementanticipe: '\e874';
$icon-pfhag-paiementanticipe-2: '\e875';
$icon-pfhag-facture: '\e876';
$icon-pfhag-invoice: '\e877';
$icon-pfhag-social-twitter: '\e81e'; /* '' */
$icon-pfhag-info-circled-1: '\e820';
$icon-pfhag-suche-schliessen: '\e81f';
$icon-pfhag-zurueck: '\e821';
$icon-pfhag-gratisgeschenk: '\e822';
$icon-pfhag-suche-blog: '\e8d7';
$icon-pfhag-suche-produkte: '\e8d8';

.icon-pfhag-arrow-right::before { content: $icon-pfhag-arrow-right; } /* '' */
.icon-pfhag-arrow-left::before { content: $icon-pfhag-arrow-left; } /* '' */
.icon-pfhag-arrow-bold-right::before { content: $icon-pfhag-arrow-bold-right; } /* '' */
.icon-pfhag-arrow-bold-left::before { content: $icon-pfhag-arrow-bold-left; } /* '' */
.icon-pfhag-arrow-down::before { content: $icon-pfhag-arrow-down; } /* '' */
.icon-pfhag-arrow-up::before { content: $icon-pfhag-arrow-up; } /* '' */
.icon-pfhag-x::before { content: $icon-pfhag-x; } /* '' */
.icon-pfhag-search::before { content: $icon-pfhag-search; } /* '' */
.icon-pfhag-warenkorb2::before { content: $icon-pfhag-warenkorb2; } /* '' */
.icon-pfhag-chat::before { content: $icon-pfhag-chat; } /* '' */
.icon-pfhag-kreditkarte::before { content: $icon-pfhag-kreditkarte; } /* '' */
.icon-pfhag-rechnung::before { content: $icon-pfhag-rechnung; } /* '' */
.icon-pfhag-tipp::before { content: $icon-pfhag-tipp; } /* '' */
.icon-pfhag-neu::before { content: $icon-pfhag-neu; } /* '' */
.icon-pfhag-bar::before { content: $icon-pfhag-bar; } /* '' */
.icon-pfhag-auswahl::before { content: $icon-pfhag-auswahl; } /* '' */
.icon-pfhag-preise::before { content: $icon-pfhag-preise; } /* '' */
.icon-pfhag-clock::before { content: $icon-pfhag-clock; } /* '' */
.icon-pfhag-pen::before { content: $icon-pfhag-pen; } /* '' */
.icon-pfhag-star::before { content: $icon-pfhag-star; } /* '' */
.icon-pfhag-reload::before { content: $icon-pfhag-reload; } /* '' */
.icon-pfhag-vergleichen::before { content: $icon-pfhag-vergleichen; } /* '' */
.icon-pfhag-discount::before { content: $icon-pfhag-discount; } /* '' */
.icon-pfhag-phone::before { content: $icon-pfhag-phone; } /* '' */
.icon-pfhag-phone2::before { content: $icon-pfhag-phone2; } /* '' */
.icon-pfhag-menu::before { content: $icon-pfhag-menu; } /* '' */
.icon-pfhag-texting::before { content: $icon-pfhag-texting; } /* '' */
.icon-pfhag-hacken-kreis::before { content: $icon-pfhag-hacken-kreis; } /* '' */
.icon-pfhag-questionmark::before { content: $icon-pfhag-questionmark; } /* '' */
.icon-pfhag-hacken-shield::before { content: $icon-pfhag-hacken-shield; } /* '' */
.icon-pfhag-anmelden::before { content: $icon-pfhag-anmelden; } /* '' */
.icon-pfhag-bestellung-ueberpruefen::before { content: $icon-pfhag-bestellung-ueberpruefen; } /* '' */
.icon-pfhag-call::before { content: $icon-pfhag-call; } /* '' */
.icon-pfhag-grosse-auswahl::before { content: $icon-pfhag-grosse-auswahl; } /* '' */
.icon-pfhag-haeckchen::before { content: $icon-pfhag-haeckchen; } /* '' */
.icon-pfhag-herz::before { content: $icon-pfhag-herz; } /* '' */
.icon-pfhag-instagram::before { content: $icon-pfhag-instagram; } /* '' */
.icon-pfhag-klarna::before { content: $icon-pfhag-klarna; } /* '' */
.icon-pfhag-konto::before { content: $icon-pfhag-konto; } /* '' */
.icon-pfhag-lieferbar::before { content: $icon-pfhag-lieferbar; } /* '' */
.icon-pfhag-maestro::before { content: $icon-pfhag-maestro; } /* '' */
.icon-pfhag-magazin::before { content: $icon-pfhag-magazin; } /* '' */
.icon-pfhag-mail::before { content: $icon-pfhag-mail; } /* '' */
.icon-pfhag-marken::before { content: $icon-pfhag-marken; } /* '' */
.icon-pfhag-mastercard::before { content: $icon-pfhag-mastercard; } /* '' */
.icon-pfhag-nicht-lieferbar::before { content: $icon-pfhag-nicht-lieferbar; } /* '' */
.icon-pfhag-paypal::before { content: $icon-pfhag-paypal; } /* '' */
.icon-pfhag-postfinance::before { content: $icon-pfhag-postfinance; } /* '' */
.icon-pfhag-rechnung-1::before { content: $icon-pfhag-rechnung-1; } /* '' */
.icon-pfhag-sameday-lieferung::before { content: $icon-pfhag-sameday-lieferung; } /* '' */
.icon-pfhag-social-facebook::before { content: $icon-pfhag-social-facebook; } /* '' */
.icon-pfhag-social-youtube::before { content: $icon-pfhag-social-youtube; } /* '' */
.icon-pfhag-social-instagram::before { content: $icon-pfhag-social-instagram; } /* '' */
.icon-pfhag-social-linkedin::before { content: $icon-pfhag-social-linkedin; } /* '' */
.icon-pfhag-social-pinterest::before { content: $icon-pfhag-social-pinterest; } /* '' */
.icon-pfhag-sofortueberweisung::before { content: $icon-pfhag-sofortueberweisung; } /* '' */
.icon-pfhag-suchen::before { content: $icon-pfhag-suchen; } /* '' */
.icon-pfhag-twint::before { content: $icon-pfhag-twint; } /* '' */
.icon-pfhag-visa::before { content: $icon-pfhag-visa; } /* '' */
.icon-pfhag-vorkasse::before { content: $icon-pfhag-vorkasse; } /* '' */
.icon-pfhag-warenkorb::before { content: $icon-pfhag-warenkorb; } /* '' */
.icon-pfhag-whatsapp::before { content: $icon-pfhag-whatsapp; } /* '' */
.icon-pfhag-wunschliste::before { content: $icon-pfhag-wunschliste; } /* '' */
.icon-pfhag-wunschliste-aktiv::before { content: $icon-pfhag-wunschliste-aktiv; } /* '' */
.icon-pfhag-sichere-bestellung-klein::before { content: $icon-pfhag-sichere-bestellung-klein; } /* '' */
.icon-pfhag-kostenlose-beratung-klein::before { content: $icon-pfhag-kostenlose-beratung-klein; } /* '' */
.icon-pfhag-coiffeurprodukte-klein::before { content: $icon-pfhag-coiffeurprodukte-klein; } /* '' */
.icon-pfhag-gratis-internet-klein::before { content: $icon-pfhag-gratis-internet-klein; } /* '' */
.icon-pfhag-online-terminbuchung-klein::before { content: $icon-pfhag-online-terminbuchung-klein; } /* '' */
.icon-pfhag-grosse-auswahl-klein::before { content: $icon-pfhag-grosse-auswahl-klein; } /* '' */
.icon-pfhag-attraktive-preise-klein::before { content: $icon-pfhag-attraktive-preise-klein; } /* '' */
.icon-pfhag-haarstyling::before { content: $icon-pfhag-haarstyling; } /* '' */
.icon-pfhag-schnelle-lieferung-klein::before { content: $icon-pfhag-schnelle-lieferung-klein; } /* '' */
.icon-pfhag-hautpflege::before { content: $icon-pfhag-hautpflege; } /* '' */
.icon-pfhag-naegel::before { content: $icon-pfhag-naegel; } /* '' */
.icon-pfhag-make-up::before { content: $icon-pfhag-make-up; } /* '' */
.icon-pfhag-haarpflege::before { content: $icon-pfhag-haarpflege; } /* '' */
.icon-pfhag-haare-faerben::before { content: $icon-pfhag-haare-faerben; } /* '' */
.icon-pfhag-tools::before { content: $icon-pfhag-tools; } /* '' */
.icon-pfhag-duefte::before { content: $icon-pfhag-duefte; } /* '' */
.icon-pfhag-adressen::before { content: $icon-pfhag-adressen; } /* '' */
.icon-pfhag-bearbeiten::before { content: $icon-pfhag-bearbeiten; } /* '' */
.icon-pfhag-tiktok::before { content: $icon-pfhag-tiktok; } /* '' */
.icon-pfhag-lieblingsprodukte::before { content: $icon-pfhag-lieblingsprodukte; } /* '' */
.icon-pfhag-email::before { content: $icon-pfhag-email; } /* '' */
.icon-pfhag-insel::before { content: $icon-pfhag-insel; } /* '' */
.icon-pfhag-tasche::before { content: $icon-pfhag-tasche; } /* '' */
.icon-pfhag-ueberraschung::before { content: $icon-pfhag-ueberraschung; } /* '' */
.icon-pfhag-nogo::before { content: $icon-pfhag-nogo; } /* '' */
.icon-pfhag-mitarbeiter::before { content: $icon-pfhag-mitarbeiter; } /* '' */
.icon-pfhag-store-locator::before { content: $icon-pfhag-store-locator; } /* '' */
.icon-pfhag-stern::before { content: $icon-pfhag-stern; } /* '' */
.icon-pfhag-abmelden::before { content: $icon-pfhag-abmelden; } /* '' */
.icon-pfhag-zeit::before { content: $icon-pfhag-zeit; } /* '' */
.icon-pfhag-zahlungsart::before { content: $icon-pfhag-zahlungsart; } /* '' */
.icon-pfhag-uebersicht::before { content: $icon-pfhag-uebersicht; } /* '' */
.icon-pfhag-bei-perfecthair-seit::before { content: $icon-pfhag-bei-perfecthair-seit; } /* '' */
.icon-pfhag-amex::before { content: $icon-pfhag-amex; } /* '' */
.icon-pfhag-prepayment::before { content: $icon-pfhag-prepayment; } /* '' */
.icon-pfhag-paiementanticipe::before { content: $icon-pfhag-paiementanticipe; } /* '' */
.icon-pfhag-paiementanticipe-2::before { content: $icon-pfhag-paiementanticipe-2; } /* '' */
.icon-pfhag-facture::before { content: $icon-pfhag-facture; } /* '' */
.icon-pfhag-invoice::before { content: $icon-pfhag-invoice; } /* '' */
.icon-pfhag-social-twitter::before { content: $icon-pfhag-social-twitter; } /* '' */
.icon-pfhag-info-circled-1::before { content: $icon-pfhag-info-circled-1; } /* '' */
.icon-pfhag-suche-schliessen::before { content: $icon-pfhag-suche-schliessen; } /* '' */
.icon-pfhag-zurueck::before { content: $icon-pfhag-zurueck; } /* '' */
.icon-pfhag-gratisgeschenk::before { content: $icon-pfhag-gratisgeschenk; } /* '' */
.icon-pfhag-suche-blog::before { content: $icon-pfhag-suche-blog; };
.icon-pfhag-suche-produkte::before { content: $icon-pfhag-suche-produkte; };

@import 'compare-images';
@import 'image-grid';
@import 'accordion';
@import 'daily-deal';
@import 'spcial-effects';
@import 'unicontent';

.content-youtube-video-true-ar iframe {
  width: 100% !important;
  height: 100% !important;
}
.content-youtube-video iframe {
  width: 100% !important;
  height: 100% !important;
}

.emotion-iframe {
  min-height: 1500px;
}

.teaser-wrapper-desktop {
  display: block;
  //z-index: 5001;
  width: 100%;
  max-height: 40px;

  @media (min-width: 768px) {
    position: inherit;
  }

  .mobile-wrapper {
    width: 100%;

    .teaser-close {
      border: 1px solid #fff;
      border-radius: 50%;
      padding: 5px 7px;
    }
  }

  .label {
    font-size: 16px;
  }

  .teaser-bob-container {
    zoom: 1;
    -moz-opacity: 0;
    margin-right: auto;
    margin-left: auto;
    -webkit-perspective: 1000px;
    -moz-transform: perspective(1000px);
    perspective: 1000px;
    -moz-transform-style: preserve-3d;
    -webkit-animation: allmoves 50s ease-in-out 3s normal forwards;
    -moz-animation: allmoves 50s ease-in-out 3s normal forwards;
    -ms-animation: allmoves 50s ease-in-out 3s normal forwards;
    -o-animation: allmoves 50s ease-in-out 3s normal forwards;
    animation: allmoves 50s ease-in-out 3s normal forwards;
    -webkit-transform-origin: 50% -80px 0;
    -ms-transform-origin: 50% -80px 0;
    transform-origin: 50% -80px 0;
    position: relative;

    filter: alpha(opacity=100);
    opacity: 1;

    animation: allmoves 50s ease-in-out 0s normal forwards;

    .teaser-desktop-close {
      position: absolute;
      background-color: whitesmoke;
      padding: 10px;
      z-index: 1050;
      top: 300px;
      left: -5px;
      border-radius: 50%;
      font-size: 20px;
    }

    .teaser-shadowed {
      max-height: unset;
      max-width: unset;
      width: 100%;
      height: auto;
    }
  }
}

@keyframes fade {
  0% { opacity: 1 }
  100% { opacity: 0 }
}
.calendar-container-ie{
  width: 100%;
  height: 100%;
  position: relative;

  .calendar-back, .calendar-front{
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
  }
  .calendar-front{
    z-index: 2;
  }
  .calendar-front:hover{
    animation: fade 1s forwards;
  }
  .ph-ac-img-back-info{
    position: absolute;
    bottom: 5%;
  }
  .calendar-back img{
    opacity: 0.1;
  }
  .ph-text-box {
    position: absolute;
    text-align: center;
    color: #4d4e4d;
  }
}


.accordion {
    &-header {
        &[aria-expanded="true"] {
            .toggle {
                &-open {
                    display: none;
                }

                &-close {
                    display: block;
                }
            }

            .fa-circle-chevron-down {
                display: none;
            }

            .fa-circle-chevron-up {
                display: inline-block;
            }

            .label {
                &-open {
                    display: none;
                }

                &-close {
                    display: inline-block;
                }
            }
        }

        &[aria-expanded="false"] {
            .toggle {
                &-open {
                    display: block;
                }

                &-close {
                    display: none;
                }
            }

            .fa-circle-chevron-down {
                display: inline-block;
            }

            .fa-circle-chevron-up {
                display: none;
            }

            .label {
                &-open {
                    display: inline-block;
                }

                &-close {
                    display: none;
                }
            }
        }
    }

    .fa-circle-chevron-up {
        display: none;
    }

    .fa-circle-chevron-down {
        display: block;
    }

    &.open {
        .fa-circle-chevron-up {
            display: block;
        }

        .fa-circle-chevron-down {
            display: none;
        }

        .col-body {
            display: block;
        }
    }

    .col-title {
        position: relative;

        i {
            position: absolute;
            right: -25px;
            top: 7px;
            transform: translateX(-50%);
            height: 40px;
            width: 40px;
        }
    }

    .col-body {
        display: none;

        @media (min-width: 768px) {
            display: block;
        }
    }

    &-button {
        &:not(.collapsed) {
            color: $black;
            background-color: transparent;
            border-color: transparent;

            &:after {
                content: "\f139";
                font-family: "Font Awesome 5 Free";
                font-weight: 900;
                background-image: none;
                margin-right: 0;
                transform: none;
            }
        }

        &.collapsed {
            &:after {
                content: "\f13a";
                font-family: "Font Awesome 5 Free";
                font-weight: 900;
                background-image: none;
            }
        }

        &:focus {
            box-shadow: none;
        }

        &:hover, &:focus {
            color: $primaryColor;
        }

        &.icon-left {
            &:after {
                margin-left:0 !important;
                margin-right: auto;
            }
        }
    }
}

.gift-rules {
    > p {
        &:not(:first-child) {
            display: none;
        }
    }

    &.open {
        > p {
            display: block;
        }
    }
}

.bx-ads {
  &-preview {
    > a {
      display: none;

      &:first-child {
        display: flex;
      }

      @media (min-width: 768px) {
        display: flex;
      }
    }
  }
}
.bx-banner-product-img {
  max-height: 173px;
  width: auto;
  height: 100%;

  @media (min-width: 768px) {
    min-height: unset;
    max-height: 175px;
    width: auto;
  }

  @media (min-width: 1200px) {
    min-height: 278px;
    max-height: 280px;
    width: auto;
    height: 100%;
  }
}

.bx-banner-product-img-container {
  min-height: 173px;

  @media (min-width: 768px) {
    min-height: 193px;
    max-height: 175px;
  }

  @media (min-width: 1200px) {
    min-height: 278px;
  }
}
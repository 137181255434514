
.heart {
  position: fixed;
  width: 100px;
  height: 90px;
  margin-top: 10px;
  top: 0;
  left: 0;
  z-index: -1;
  opacity: 0;
}

.heart::before, .heart::after {
  content: "";
  position: absolute;
  top: 0;
  left: -150px;
  width: 52px;
  height: 80px;
  border-radius: 50px 50px 0 0;
  background: red;
}

.heart::before {
  left: 50px;
  transform: rotate(-45deg);
  transform-origin: 0 100%;
}

.heart::after {
  left: 0;
  transform: rotate(45deg);
  transform-origin: 100% 100%;
}

.snow {
  position: absolute;
  width: 10px;
  height: 10px;
  background: #ededed;
  text-shadow: #000 3px 3px 7px;
  border-radius: 50%;
  z-index: 1000;
}
.snow:nth-child(1) {
  opacity: 0.563;
  transform: translate(95.816vw, -10px) scale(0.1408);
  animation: fall-1 15s -3s linear infinite;
}
@keyframes fall-1 {
  57.988% {
    transform: translate(95.7593vw, 57.988vh) scale(0.1408);
  }
  to {
    transform: translate(95.78765vw, 100vh) scale(0.1408);
  }
}
.snow:nth-child(2) {
  opacity: 0.8605;
  transform: translate(87.9696vw, -10px) scale(0.0959);
  animation: fall-2 17s -7s linear infinite;
}
@keyframes fall-2 {
  64.337% {
    transform: translate(90.7914vw, 64.337vh) scale(0.0959);
  }
  to {
    transform: translate(89.3805vw, 100vh) scale(0.0959);
  }
}
.snow:nth-child(3) {
  opacity: 0.075;
  transform: translate(38.9759vw, -10px) scale(0.7521);
  animation: fall-3 19s -28s linear infinite;
}
@keyframes fall-3 {
  69.421% {
    transform: translate(45.8182vw, 69.421vh) scale(0.7521);
  }
  to {
    transform: translate(42.39705vw, 100vh) scale(0.7521);
  }
}
.snow:nth-child(4) {
  opacity: 0.5086;
  transform: translate(32.8923vw, -10px) scale(0.8243);
  animation: fall-4 20s -21s linear infinite;
}
@keyframes fall-4 {
  40.817% {
    transform: translate(28.1004vw, 40.817vh) scale(0.8243);
  }
  to {
    transform: translate(30.49635vw, 100vh) scale(0.8243);
  }
}
.snow:nth-child(5) {
  opacity: 0.8866;
  transform: translate(59.9938vw, -10px) scale(0.2007);
  animation: fall-5 23s -25s linear infinite;
}
@keyframes fall-5 {
  76.467% {
    transform: translate(69.6085vw, 76.467vh) scale(0.2007);
  }
  to {
    transform: translate(64.80115vw, 100vh) scale(0.2007);
  }
}
.snow:nth-child(6) {
  opacity: 0.0428;
  transform: translate(39.1276vw, -10px) scale(0.5281);
  animation: fall-6 14s -26s linear infinite;
}
@keyframes fall-6 {
  47.821% {
    transform: translate(35.6248vw, 47.821vh) scale(0.5281);
  }
  to {
    transform: translate(37.3762vw, 100vh) scale(0.5281);
  }
}
.snow:nth-child(7) {
  opacity: 0.3073;
  transform: translate(96.0842vw, -10px) scale(0.2704);
  animation: fall-7 28s -24s linear infinite;
}
@keyframes fall-7 {
  37.554% {
    transform: translate(103.7266vw, 37.554vh) scale(0.2704);
  }
  to {
    transform: translate(99.9054vw, 100vh) scale(0.2704);
  }
}
.snow:nth-child(8) {
  opacity: 0.0428;
  transform: translate(36.3766vw, -10px) scale(0.667);
  animation: fall-8 17s -25s linear infinite;
}
@keyframes fall-8 {
  31.009% {
    transform: translate(34.984vw, 31.009vh) scale(0.667);
  }
  to {
    transform: translate(35.6803vw, 100vh) scale(0.667);
  }
}
.snow:nth-child(9) {
  opacity: 0.3439;
  transform: translate(30.5014vw, -10px) scale(0.4905);
  animation: fall-9 11s -1s linear infinite;
}
@keyframes fall-9 {
  33.166% {
    transform: translate(35.2092vw, 33.166vh) scale(0.4905);
  }
  to {
    transform: translate(32.8553vw, 100vh) scale(0.4905);
  }
}
.snow:nth-child(10) {
  opacity: 0.6822;
  transform: translate(20.0926vw, -10px) scale(0.4918);
  animation: fall-10 13s -28s linear infinite;
}
@keyframes fall-10 {
  60.743% {
    transform: translate(29.0471vw, 60.743vh) scale(0.4918);
  }
  to {
    transform: translate(24.56985vw, 100vh) scale(0.4918);
  }
}
.snow:nth-child(11) {
  opacity: 0.3557;
  transform: translate(20.7717vw, -10px) scale(0.4108);
  animation: fall-11 14s -30s linear infinite;
}
@keyframes fall-11 {
  46.109% {
    transform: translate(20.7085vw, 46.109vh) scale(0.4108);
  }
  to {
    transform: translate(20.7401vw, 100vh) scale(0.4108);
  }
}
.snow:nth-child(12) {
  opacity: 0.7945;
  transform: translate(55.2859vw, -10px) scale(0.1735);
  animation: fall-12 29s -25s linear infinite;
}
@keyframes fall-12 {
  48.509% {
    transform: translate(49.1328vw, 48.509vh) scale(0.1735);
  }
  to {
    transform: translate(52.20935vw, 100vh) scale(0.1735);
  }
}
.snow:nth-child(13) {
  opacity: 0.1098;
  transform: translate(28.0814vw, -10px) scale(0.9849);
  animation: fall-13 21s -5s linear infinite;
}
@keyframes fall-13 {
  42.241% {
    transform: translate(19.4379vw, 42.241vh) scale(0.9849);
  }
  to {
    transform: translate(23.75965vw, 100vh) scale(0.9849);
  }
}
.snow:nth-child(14) {
  opacity: 0.7382;
  transform: translate(2.6604vw, -10px) scale(0.0325);
  animation: fall-14 13s -5s linear infinite;
}
@keyframes fall-14 {
  60.807% {
    transform: translate(1.902vw, 60.807vh) scale(0.0325);
  }
  to {
    transform: translate(2.2812vw, 100vh) scale(0.0325);
  }
}
.snow:nth-child(15) {
  opacity: 0.8015;
  transform: translate(88.8103vw, -10px) scale(0.4237);
  animation: fall-15 20s -24s linear infinite;
}
@keyframes fall-15 {
  32.933% {
    transform: translate(96.0535vw, 32.933vh) scale(0.4237);
  }
  to {
    transform: translate(92.4319vw, 100vh) scale(0.4237);
  }
}
.snow:nth-child(16) {
  opacity: 0.518;
  transform: translate(35.5481vw, -10px) scale(0.084);
  animation: fall-16 15s -27s linear infinite;
}
@keyframes fall-16 {
  62.255% {
    transform: translate(39.7122vw, 62.255vh) scale(0.084);
  }
  to {
    transform: translate(37.63015vw, 100vh) scale(0.084);
  }
}
.snow:nth-child(17) {
  opacity: 0.8416;
  transform: translate(41.0792vw, -10px) scale(0.5667);
  animation: fall-17 12s -16s linear infinite;
}
@keyframes fall-17 {
  68.34% {
    transform: translate(31.5768vw, 68.34vh) scale(0.5667);
  }
  to {
    transform: translate(36.328vw, 100vh) scale(0.5667);
  }
}
.snow:nth-child(18) {
  opacity: 0.9625;
  transform: translate(25.2386vw, -10px) scale(0.7273);
  animation: fall-18 28s -27s linear infinite;
}
@keyframes fall-18 {
  66.766% {
    transform: translate(31.1236vw, 66.766vh) scale(0.7273);
  }
  to {
    transform: translate(28.1811vw, 100vh) scale(0.7273);
  }
}
.snow:nth-child(19) {
  opacity: 0.1982;
  transform: translate(53.4581vw, -10px) scale(0.8994);
  animation: fall-19 16s -24s linear infinite;
}
@keyframes fall-19 {
  39.826% {
    transform: translate(61.7719vw, 39.826vh) scale(0.8994);
  }
  to {
    transform: translate(57.615vw, 100vh) scale(0.8994);
  }
}
.snow:nth-child(20) {
  opacity: 0.292;
  transform: translate(78.3775vw, -10px) scale(0.0141);
  animation: fall-20 30s -22s linear infinite;
}
@keyframes fall-20 {
  63.056% {
    transform: translate(75.2926vw, 63.056vh) scale(0.0141);
  }
  to {
    transform: translate(76.83505vw, 100vh) scale(0.0141);
  }
}
.snow:nth-child(21) {
  opacity: 0.944;
  transform: translate(85.6767vw, -10px) scale(0.0342);
  animation: fall-21 18s -11s linear infinite;
}
@keyframes fall-21 {
  58.043% {
    transform: translate(78.6474vw, 58.043vh) scale(0.0342);
  }
  to {
    transform: translate(82.16205vw, 100vh) scale(0.0342);
  }
}
.snow:nth-child(22) {
  opacity: 0.2808;
  transform: translate(44.4222vw, -10px) scale(0.158);
  animation: fall-22 24s -8s linear infinite;
}
@keyframes fall-22 {
  65.45% {
    transform: translate(38.8543vw, 65.45vh) scale(0.158);
  }
  to {
    transform: translate(41.63825vw, 100vh) scale(0.158);
  }
}
.snow:nth-child(23) {
  opacity: 0.9464;
  transform: translate(15.0723vw, -10px) scale(0.5362);
  animation: fall-23 21s -23s linear infinite;
}
@keyframes fall-23 {
  59.213% {
    transform: translate(16.6543vw, 59.213vh) scale(0.5362);
  }
  to {
    transform: translate(15.8633vw, 100vh) scale(0.5362);
  }
}
.snow:nth-child(24) {
  opacity: 0.1241;
  transform: translate(20.2993vw, -10px) scale(0.8218);
  animation: fall-24 25s -27s linear infinite;
}
@keyframes fall-24 {
  37.41% {
    transform: translate(27.0976vw, 37.41vh) scale(0.8218);
  }
  to {
    transform: translate(23.69845vw, 100vh) scale(0.8218);
  }
}
.snow:nth-child(25) {
  opacity: 0.0732;
  transform: translate(78.4145vw, -10px) scale(0.3178);
  animation: fall-25 28s -7s linear infinite;
}
@keyframes fall-25 {
  55.761% {
    transform: translate(86.7566vw, 55.761vh) scale(0.3178);
  }
  to {
    transform: translate(82.58555vw, 100vh) scale(0.3178);
  }
}
.snow:nth-child(26) {
  opacity: 0.5474;
  transform: translate(37.6547vw, -10px) scale(0.101);
  animation: fall-26 23s -30s linear infinite;
}
@keyframes fall-26 {
  58.809% {
    transform: translate(46.568vw, 58.809vh) scale(0.101);
  }
  to {
    transform: translate(42.11135vw, 100vh) scale(0.101);
  }
}
.snow:nth-child(27) {
  opacity: 0.003;
  transform: translate(30.5127vw, -10px) scale(0.3792);
  animation: fall-27 23s -8s linear infinite;
}
@keyframes fall-27 {
  57.543% {
    transform: translate(30.8197vw, 57.543vh) scale(0.3792);
  }
  to {
    transform: translate(30.6662vw, 100vh) scale(0.3792);
  }
}
.snow:nth-child(28) {
  opacity: 0.3396;
  transform: translate(31.4025vw, -10px) scale(0.6187);
  animation: fall-28 11s -22s linear infinite;
}
@keyframes fall-28 {
  62.792% {
    transform: translate(31.0671vw, 62.792vh) scale(0.6187);
  }
  to {
    transform: translate(31.2348vw, 100vh) scale(0.6187);
  }
}
.snow:nth-child(29) {
  opacity: 0.6093;
  transform: translate(61.4985vw, -10px) scale(0.2242);
  animation: fall-29 21s -21s linear infinite;
}
@keyframes fall-29 {
  35.616% {
    transform: translate(69.238vw, 35.616vh) scale(0.2242);
  }
  to {
    transform: translate(65.36825vw, 100vh) scale(0.2242);
  }
}
.snow:nth-child(30) {
  opacity: 0.8409;
  transform: translate(72.7543vw, -10px) scale(0.5896);
  animation: fall-30 28s -10s linear infinite;
}
@keyframes fall-30 {
  57.814% {
    transform: translate(73.3933vw, 57.814vh) scale(0.5896);
  }
  to {
    transform: translate(73.0738vw, 100vh) scale(0.5896);
  }
}
.snow:nth-child(31) {
  opacity: 0.0003;
  transform: translate(48.6212vw, -10px) scale(0.9253);
  animation: fall-31 10s -9s linear infinite;
}
@keyframes fall-31 {
  79.93% {
    transform: translate(40.8225vw, 79.93vh) scale(0.9253);
  }
  to {
    transform: translate(44.72185vw, 100vh) scale(0.9253);
  }
}
.flbx-webp {
    .bzfy-o-context {
        height: 215px;
    }

    .bzfy-o-container {
        max-width: 600px !important;
        margin-left: 0;
    }
}

.bzfy-gate-powered-by, .bzfy-gate-powered-by__image {
    display: none !important;
}

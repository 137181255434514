.fslightbox {
  &-full-dimension {
    background-color: #fff;
  }

  &-slide-btn {
    border-radius: 15px;
    background: black !important;
    padding: 10px 15px !important;
  }

  &-toolbar-button {
    background: $black !important;
  }
}

.lightbox-full-image {
  max-width: 80%;
  height: auto;

  //height: auto;
  //width: 100%;

  //@media (min-width: 768px) {
  //  height: 100%;
  //  width: auto;
  //}
}

#lightboxContainer {
  background-color: $white;
}
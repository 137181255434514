.highlight-bubble-slider {
  .highlight-bubble {
    width: 140px;

    @media screen and (min-width: 1200px) {
      width: 160px;
    }

    @media screen and (min-width: 1400px) {
      width: 180px;
    }
  }
}

.btn {
  border: none;
  position: relative;
  padding: 12px 0;
  font-size: 0.9rem;
  font-weight: bold;

  &-icon {
    min-width: 45px;
    min-height: 30px;
  }

  &.button-transition {
    &:hover {
      color: $black;
    }
  }

  &.no-hover {
    &:hover {
      cursor: default;
      -webkit-box-shadow: none;
      box-shadow: none;
    }
  }

  &-transparent {
    &:hover {
      -webkit-box-shadow: none !important;
      box-shadow: none !important;
    }
  }

  &-black {
    background-color: $black !important;

    span, i {
      color: $white;
    }

    &:hover {
      background-color: $black !important;
    }
  }

  &:focus {
    box-shadow: none;
    color: $black;
  }

  &:hover {
    box-shadow: none;
  }

  @media (min-width: 768px) {
    padding: 13px 0;
  }

  &.btn-low {
    @media (min-width: 768px) {
      padding: 8px 0;
    }
  }

  span {
    font-size: 0.9rem;
    font-weight: bold;
  }

  &-primary {
    color: $white;
    //background: linear-gradient(to right, $white 50%, $primaryColor 50%);
    //background-size: 200% 100%;
    //background-position: right bottom;
    //transition: all .5s ease-out;
    border: 1px solid $primaryColor;
    background: linear-gradient(to right, $white 50%, $primaryColor 50%);
    background-size: 600% 100%;
    background-position: 301% 200%;
    transition: all .9s ease-out;

    &:focus {
      border-color: $primaryColor;
      background-color: $primaryColor;
    }

    &:hover {
      color: $white;
      background-color: $primaryColorLight;
      border-color: $primaryColor;
    }
  }

  &-secondary {
    color: $black;
    border: 1px solid $secondaryColor;
    background: linear-gradient(to right, $white 50%, $secondaryColor 50%);
    background-size: 600% 100%;
    background-position: 301% 200%;
    transition: all .9s ease-out;

    &:focus {
      border-color: $secondaryColor;
    }

    &:hover {
      color: $black;
      border: 1px solid $secondaryColor;
    }
  }

  &-alt {
    border: 1px solid lighten($black, 15);
    background-color: transparent;

    &:hover {
      border: 1px solid $black;
      box-shadow: none;
    }

    &.border-top-bottom {
      border-top: 1px solid $gray;
      border-bottom: 1px solid $gray;
      border-left: none;
      border-right: none;
      border-radius: 0;

      &:focus {
        box-shadow: none;
      }

      &:hover {
        box-shadow: none;
        border-color: $gray;
      }
    }
  }

  &-icon {
    i {
      position: absolute;
      right: 15px;
      top: 50%;
      transform: translateY(-50%);
      bottom: 0;
      margin-top: auto;
      margin-bottom: auto;
      width: 20px;
      height: 20px;
    }
  }

  .fa-check {
    color: $green;
  }

  .fa-triangle-exclamation {
    color: $red;
  }
}

.button-transition {
  //background-position: left bottom;
  background-position: 250% 200%;
  color: $black;
}

.is-invalid {
  border: 1px solid $red !important;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23c74366'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23c74366' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.message {
  &-bar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 6750;
    text-align: center;
    opacity: 0.92;

    .alert {
      padding: 30px 15px;
      min-height: 40px;

      @media (min-width: 992px) {
        min-height: 60px;
      }
    }
  }

  &-container {
    max-width: 1000px;
  }
}
.product {
  &-badge {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    vertical-align: middle;
  }
}

.color-card {
  &-badge {
    border-radius: 50%;
    line-height: 40px;
    text-align: center;
    vertical-align: middle;
  }
}

.product-detail-container {
  .badge-container {
    position: absolute;
    z-index: 10;
    width: 100%;
  }
  @media (min-width: 576px) {
    .badge-container {
      position: relative;
      width: auto;
    }
  }
}
html {
  font-size: 16px;
}

html, body {
  overflow: auto;
}

body {
  position: relative;
}

* {
  color: $black;
}

a, [role=button] {
  text-decoration: none;
  color: $black;

  &.hover-white {
    &:hover {
      color: $white;
    }
  }

  &:hover {
    color: $primaryColor;
  }

  &.dark {
    &:hover {
      color: lighten($black, 30%);
    }
  }

  &.persist {
    &:hover {
      color: $black;
    }
  }
}

.container {
  @media (min-width: 768px) {
    width: 1180px;
  }

  &-small {
    @media (min-width: 768px) {
      max-width: 650px;
    }
  }
}

.bg-primary {
  background-color: $primaryColor !important;
}

.bg-primary-light {
  background-color: #CEE4E9;
}

.text-primary {
  color: $primaryColor !important;

  * {
    color: $primaryColor !important;
  }
}

.divider {
  border-top: 1px solid $grayMid;
  height: 1px;
}

.border-top-black {
  border-top: 1px solid $black;
}

.border-top-bottom-primary-dark {
  border-top: 1px solid $primaryColorDark;
  border-bottom: 1px solid $primaryColorDark;
}

.border-bottom-black {
  border-bottom: 1px solid $black;
}

.border-bottom-black-light {
  border-bottom: 1px solid rgba(199, 185, 185, 0.2) !important;
}

.border-bottom-gray {
  border-bottom: 1px solid $gray !important;
}

.border-bottom-gray-alpha {
  border-bottom: 1px solid rgba(65, 65, 65, 0.15) !important;
}

.border-light {
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
}

.border {
  border: 1px solid $grayMid !important;
  border-radius: 7px;

  &.no-hover {
    &:hover {
      border-color: $grayMid !important;
    }
  }

  &-secondary {
    &.no-hover {
      &:hover {
        border-color: $secondaryColor !important;
      }
    }
  }

  &:hover {
    border-color: lighten($black, 10) !important;
  }

  &-clear {
    border: none;
  }

  &-trans {
    border: 1px solid transparent;
    border-radius: 7px;
  }

  &-radius-light {
    border-radius: 6px;
  }

  &-green {
    border: 1px solid $green !important;
  }

  &-red {
    border: 1px solid $red !important;
  }

  &-green-thick {
    border: 2px solid $green;
  }

  &-black-thick {
    border: 2px solid $black;
  }

  &-primary {
    border: 1px solid $primaryColor !important;

    &:hover {
      border: 1px solid darken($primaryColor, 20%);
    }
  }

  &-secondary {
    border: 1px solid $secondaryColor !important;

    &:hover {
      border: 1px solid darken($secondaryColor, 20%);
    }
  }
}

.rounded {
  &-4 {
    border-radius: 0.5rem !important;
  }

  &-5{
    //border-radius: 0.7rem !important;
  }
}

.no-border-radius {
  border-radius: unset;
}

.clr-primary {
  color: $primaryColor;
}

.bg-secondary {
  background-color: $secondaryColor !important;
}

.bg-secondary-dark {
  background-color: $secondaryColorDark !important;
}

.bg-dark-alpha {
  background-color: rgba(0, 0, 0, 0.65);
}

.bg-gray {
  background-color: rgba(202, 202, 202, 0.65);
}

.clr-secondary {
  color: $secondaryColor;
}

.bg-highlight {
  //background-color: $highlightColor;
}

.bg-white {
  background-color: $white;
}

.bg-green {
  background-color: $green;
}

.bg-gold {
  background-color: #DAAA5A;
}

.bg-tertiary {
  background-color: $tertiaryColor;
}

.bg-red {
  background-color: $red;
}

.bg-black {
  background-color: $black !important;
}

.primary-color {
  color: $primaryColor;
}

.gold-color {
  color: #DAAA5A;
}

.tertiaryColor {
  color: $tertiaryColor;
}

.primary-fill {
  background-color: lighten($primaryColor, 15%);

  &:hover {
    background-color: $primaryColor;
  }
}

.primary-hover-fill {
  &:hover {
    border: 1px solid transparent !important;
    background-color: lighten($primaryColor, 15%);
  }
}

.secondary-color {
  color: $secondaryColor;
}

.element-h-center {
  margin-left: auto;
  margin-right: auto;
}

.element-v-center {
  margin-top: auto;
  margin-bottom: auto;
}

.absolute-v-center {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.absolute-h-center {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.relative-v-center {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.relative-h-center {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

.relative-end {
  right: 0;
}

.content-center {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.content-main {
  margin-top: 50px;
  padding-top: 90px;

  @media (min-width: 768px) {
    margin-top: 85px;
    position: relative;
    padding-top: 0;
  }

  @media (min-width: 1024px) {
    margin-top: 115px;
  }
}

.ellipsis-text {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-word;
  overflow: hidden;
  hyphens: auto;
  text-align: left;
}

.box-shadow {
  -webkit-box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgba(0, 0, 0, 0);
  box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgba(0, 0, 0, 0);
}

.box-shadow-2 {
  -webkit-box-shadow: -10px 0px 13px -7px #000000, 10px 0px 13px -7px #000000, 5px 5px 15px 5px rgba(0, 0, 0, 0);
  box-shadow: -10px 0px 13px -7px #000000, 10px 0px 13px -7px #000000, 5px 5px 15px 5px rgba(0, 0, 0, 0);
}

.box-shadow-3 {
  box-shadow: 5px 5px 20px -4px rgba(0, 0, 0, 0.63);
  -webkit-box-shadow: 5px 5px 20px -4px rgba(0, 0, 0, 0.63);
  -moz-box-shadow: 5px 5px 20px -4px rgba(0, 0, 0, 0.63);
}

.text-green {
  color: $green;
}

.text-red {
  color: $red;
}

.text-orange {
  color: $orange;
}

.icon-right {
  position: relative;

  > i {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
  }

  i {
    display: none;

    &:first-of-type {
      display: block;
    }
  }
}

img {
  width: auto;
}

.w-md-50 {
  width: 100%;

  @media (min-width: 768px) {
    width: 50% !important;
  }
}

.w-md-33 {
  @media (min-width: 768px) {
    width: 33.3% !important;
  }
}

.w-lg-25 {
  @media (min-width: 992px) {
    width: 25% !important;
  }
}

.position-md-absolute {
  @media (min-width: 768px) {
    position: absolute !important;
  }
}

.position-xl-absolute {
  @media (min-width: 1200px) {
    position: absolute !important;
  }
}

.position-md-relative {
  @media (min-width: 768px) {
    position: relative !important;
  }
}

.w-90 {
  width: 90%;
}

.w-49 {
  width: 100%;
  @media (min-width: 768px) {
    width: 49%;
  }
}

.w-48 {
  width: 100%;
  @media (min-width: 768px) {
    width: 48%;
  }
}

.w-33 {
  width: 100%;
  @media (min-width: 768px) {
    width: 33.3%;
  }
}

.w-30 {
  width: 100%;
  @media (min-width: 768px) {
    width: 30%;
  }
}

.w-100-auto {
  width: 100%;
  @media (min-width: 768px) {
    width: auto;
  }
}

.h-md-100 {
  height: auto;
  @media (min-width: 768px) {
    height: 100%;
  }
}

.top-25 {
  top: 25%;
}

.progress-bar {
  background-color: $black;
}

.order-dialog {
  max-width: 800px;

  .modal-content {
    padding: 25px;
  }
}

.label-row {
  @media (max-width: 767px) {
    > div {
      &:before {
        content: attr(data-label);
        font-weight: bold;
      }

      span {
        display: block;
      }
    }
  }
}

.open {
  display: block !important;

  > div {
    display: block !important;
  }
}

.overflow-hidden {
  overflow: hidden;
}

[v-cloak] {
  display: none;
}

.brand {
  &-anchor {
    overflow-x: auto;

    @media (min-width: 768px) {
      overflow-x: auto;
    }
  }
}

.hidden {
  visibility: hidden;
}

.image-contain {
  object-fit: contain;
}

.image-cover {
  object-fit: cover;
}

table {
  th {
    font-weight: normal;
  }
}

.overlay-text {
  color: $white;

  * {
    color: $white;
  }
}

.bx-detail-recs {
  min-height: 2177px;

  @media (min-width: 1024px) {
    min-height: 2325px;
  }
}

.product-detail-container {
  min-height: 925px;

  @media (min-width: 768px) {
    min-height: 502px;
  }
}

.slide-min-height {
  min-height: 307px;

  @media (min-width: 768px) {
    min-height: 430px;
  }
}


.hover-bg-primary {
  &:hover {
    background-color: lighten($primaryColor, 50);
  }
}

[class*="css"],
[class*="react-datepicker"],
.MuiSelect-select em,
.ql-editor {
  font-family: 'Poppins', sans-serif !important;
  font-size: 0.9rem !important;
  color: $black !important;
}

.gift-info-img {
  max-height: 100px;

  @media(min-width: 768px) {
    max-height: 150px;
  }
}

.bx-ad-banner {
  p {
    display: inline;
  }
}

.seo-text {
  a {
    color: $primaryColor;
  }

  h1 {
    font-size: 25px;

    * {
      font-size: 25px;
    }
  }

  h2 {
    font-size: 22px;
    margin: 40px 0 20px;

    * {
      font-size: 22px;
    }
  }

  h3 {
    font-size: 19px;
    margin: 20px 0 15px;

    * {
      font-size: 19px;
    }
  }

  h4 {
    font-size: 17px;
    margin: 10px 0 10px;

    * {
      font-size: 17px;
    }
  }
}

.text-white {
  * {
    color: $white;
  }
}

.bg-black-light {
  background-color: rgb(60, 60, 60);
}

.bullet-list-centered {
  list-style-position: inside;
  padding: 0;
  margin: 0 auto;
  width: fit-content;
  text-align: center;

  li {
    display: list-item;
    margin-bottom: 10px;
  }
}
.content-product-carousel {
  &[data-per-page="1"] {
    @media (min-width: 768px) {
      max-width: 290px;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.content-brand-slider .grid-item {
  max-width: 50%;
  padding: 0.5rem;

}
@media (min-width: 768px) {
  .content-brand-slider .grid-item {
    max-width: 200px;
    padding: 1rem;
  }
}

.dark-theme-content .dark-theme-text,
.dark-theme-content .dark-theme-text * {
  color: #fff;
}

.dark-theme-content .dark-theme-bg-white {
  background-color: #fff;
}

.z-index-10 {
  z-index: 10;
}

@media (min-width: 768px) {
  .content-product-carousel {
    min-height: 350px;
  }
}

.unievent-container{
  display:flex;
  flex-wrap:wrap;
  justify-content:space-around;
}
.unievent-card{
  position:relative;
  margin:20px 0;
  width:100%;
  min-height:400px;
  background: #fff;
  transform-style:preserve-3d;
  transform:perspective(2000px);
  transition:1s;
  transition-delay: 500ms;
  box-shadow:inset 0 0 10px rgba(0, 0, 0, 0.5);
}
.unievent-card:hover{
  z-index:1111;
  box-shadow:inset 0 0 10px rgba(0, 0, 0, 0.5);
}
.unievent-card .unievent-img-container .unievent-desktop {
  display:none;
}
@media (min-width: 768px) {
  .unievent-card .unievent-img-container .unievent-mobile {
    display:none;
  }
  .unievent-card .unievent-img-container .unievent-desktop {
    display:block;
  }
}
.unievent-card .unievent-img-container{
  position:absolute;
  width:100%;
  height:100%;
  box-sizing:border-box;
  transform-origin:left;
  z-index:1;
  transition:1s;
}
.unievent-card .unievent-img-container img{
  position:absolute;
  left:0;
  top:0;
  height:100%;
  width:100%;
  object-fit:cover;
}
.unievent-card:hover .unievent-img-container{
  transform:rotateY(-135deg);
}
.unievent-card .unievent-card-details{
  position:relative;
  left:0;
  top:0;
  width: 100%;
  height: 100%;
  box-sizing:border-box;
  padding:20px;
  color:#000;
}
.unievent-card .unievent-card-details img{
  max-width: 200px;
}
.eventcalendar-past {
  opacity: 0.5;
}
.eventcalendar-p{
  max-width: 500px;
}
.max-width-80 {
  max-width: 80%;
}


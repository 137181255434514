html, body {
  font-family: 'Poppins Light';
}

* {
  font-size: 0.9rem;
}

h1 {
  font-size: 20px;
  font-weight: bold;
}

h2 {
  font-size: 18px;
  font-weight: bold;
}

h3 {
  font-size: 17px;
  font-weight: bold;
}

h4 {
  font-size: 16px;
  font-weight: bold;
}

h5 {
  font-size: 15px;
  font-weight: bold;
}

h6 {
  font-size: 14px;
  font-weight: bold;
}

b, strong {
  font-weight: bold;
}

.sup {
  font-size: 0.9rem;
}

.sup-1 {
  font-size: 0.8rem;
}

.sup-2 {
  font-size: 0.7rem;
}

.fs-xs-1 {
  @media (max-width: 767px) {
    font-size: calc(1.375rem + 1.3vw) !important;
  }
}

.fs-xs-2 {
  @media (max-width: 767px) {
    font-size: calc(1.325rem + 0.6vw) !important;
  }
}

.fs-xs-3 {
  @media (max-width: 767px) {
    font-size: calc(1.3rem + 0.3vw) !important;
  }
}

.fs-xs-4 {
  @media (max-width: 767px) {
    font-size: calc(1.275rem + 0.1vw) !important;
  }
}

.fs-xs-5 {
  @media (max-width: 767px) {
    font-size: 0.9rem !important;
  }
}

.fs-xs-6 {
  @media (max-width: 767px) {
    font-size: 0.8rem !important;
  }
}

.fs-xs-7 {
  @media (max-width: 767px) {
    font-size: 0.7rem !important;
  }
}

.fs-md-5 {
  @media (min-width: 768px) {
    font-size: 1rem !important;
  }
}

.fs-6 {
  font-size: 1.15rem !important;
}

.fs-7 {
  font-size: 1rem !important;
}

.fs-8 {
  font-size: 0.9rem !important;
}

.fs-9 {
  font-size: 0.8rem !important;
}

.fs-big {
  font-size: 3rem !important;
}

.lh-2 {
  line-height: 1.8;
}

.lh-3 {
  line-height: 1.2;
}

[class*="css"] {
  font-family: 'Poppins Light', sans-serif;

  * {
    font-family: 'Poppins Light', sans-serif;
  }
}

.fw-bold,
b, strong,
h1, h2, h3, h4, h5, h6,
[class*="css"] .fw-bold,
[class*="css"] b,
[class*="css"] h1,
[class*="css"] h2,
[class*="css"] h3,
[class*="css"] h4 {
  font-family: "Poppins Bold" !important;
}

.fw-normal {
  font-family: "Poppins Light" !important;
}
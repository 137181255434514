.content-product-daily-deal {
  min-height: 500px;
}
.daily-deal-tile-h {
  min-height: 250px;
}
.daily-deal-cd-h {
  min-height: 200px;
}
.daily-deal-cd-h {
  min-height: 200px;
}

.content-product-daily-deal {
  .product-tile-text.multi-line {
    height: auto;
    min-height: 45px;
  }

  .overlay {
    background-color: #fff;
    opacity: 0.5;
    z-index: 10;
  }
}

.home-deal-wrapper .product-tile-text {
  height: 40px;
  margin-bottom: 8px;
}

.home-deal-wrapper .image-container {
  width: 125px;
  height: 125px;

  @media (min-width: 768px) {
    width: 175px;
    height: 175px;
  }
}

@media (min-width: 768px) {
  .home-deal-wrapper .progressbar-wrapper {
    height: 80px !important;
  }
}

.modal {
  &-container {
    z-index: 1060;
    width: 100%;
    background-color: $white;

    @media (min-width: 768px) {
      width: auto;
      min-width: 600px;
    }
  }
}
.grid {
  display: grid;
  grid-gap: 10px;
  //grid-auto-rows: 200px;
  grid-auto-flow: dense;
  overflow: hidden !important;

  &.col-5 {
    grid-template-columns: repeat(5, 1fr);
  }

  &.col-4 {
    grid-template-columns: repeat(4, 1fr);
  }

  &.col-md-2 {
    @media (min-width: 768px) {
      grid-template-columns: repeat(2, 1fr) !important;
    }
  }

  &.col-md-3 {
    @media (min-width: 768px) {
      grid-template-columns: repeat(3, 1fr) !important;
    }
  }

  &.col-md-4 {
    @media (min-width: 768px) {
      grid-template-columns: repeat(4, 1fr) !important;
    }
  }

  &.col-md-5 {
    @media (min-width: 768px) {
      grid-template-columns: repeat(5, 1fr) !important;
    }
  }

  &.col-md-6 {
    @media (min-width: 768px) {
      grid-template-columns: repeat(6, 1fr) !important;
    }
  }

  &.col-md-7 {
    @media (min-width: 768px) {
      grid-template-columns: repeat(7, 1fr) !important;
    }
  }

  &.col-md-8 {
    @media (min-width: 768px) {
      grid-template-columns: repeat(8, 1fr) !important;
    }
  }

  &.col-md-9 {
    @media (min-width: 768px) {
      grid-template-columns: repeat(9, 1fr) !important;
    }
  }

  &.col-md-10 {
    @media (min-width: 768px) {
      grid-template-columns: repeat(10, 1fr) !important;
    }
  }

  &.col-2 {
    grid-template-columns: repeat(2, 1fr);
  }

  &.row-1 {
    grid-template-rows: repeat(1, 1fr);
  }

  &.row-md-1 {
    @media (min-width: 768px) {
      grid-template-rows: repeat(1, 1fr);
    }
  }

  &.row-md-2 {
    @media (min-width: 768px) {
      grid-template-rows: repeat(2, 1fr);
    }
  }
}

.g-6 {
  --bs-gutter-x: 4rem;
  --bs-gutter-y: 4rem;
}

.g-7 {
  --bs-gutter-x: 5rem;
  --bs-gutter-y: 5rem;
}

.g-md-7 {
  @media (min-width: 768px) {
    --bs-gutter-x: 5rem;
    --bs-gutter-y: 5rem;
  }
}
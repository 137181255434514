.tiles {
    &.row-1 {
        @media (min-width: 768px) {
            grid-template-rows: repeat(1, 1fr) !important;
        }
    }

    &.row-2 {
        grid-template-rows: repeat(2, 1fr);

        @media (min-width: 768px) {
            grid-template-rows: repeat(2, 1fr) !important;
        }
    }

    &.row-4 {
        grid-template-rows: repeat(8, 1fr);

        @media (min-width: 768px) {
            grid-template-rows: repeat(4, 1fr);
        }
    }

    &.col-2 {
        grid-template-columns: repeat(1, 1fr);
        //grid-template-rows: repeat(1, 1fr);

        @media (min-width: 768px) {
            grid-template-columns: repeat(2, 1fr);
        }
    }

    &.col-3 {
        grid-template-columns: repeat(2, 1fr);
        //grid-template-rows: repeat(2, 1fr);

        @media (min-width: 768px) {
            grid-template-columns: repeat(3, 1fr);
        }
    }

    &.gallery {
        display: grid;
        grid-gap: 10px;
        width: 100%;
        //grid-auto-rows: 200px;
        //grid-auto-flow: dense;

        img {
            &:not(.default-img) {
                @media(min-width: 768px) {
                    height: auto;
                    width: 100%;
                    min-width: 100%;
                    min-height: 100%;
                    object-fit: cover;
                }
            }
        }

        .wide {
            grid-column: span 1;

            @media (min-width: 768px) {
                grid-column: span 2;
            }
        }

        .tall {
            grid-row: span 1;

            @media (min-width: 768px) {
                grid-row: span 2;
            }
        }

        .tile {
            &:not(.no-hover) {
                position: relative;
                border: 1px solid transparent;

                &:hover {
                    border: 1px solid rgba($black, 0.1);
                }
            }

            &-title, &-icon {
                position: absolute;
                bottom: 20px;
                background-color: $white;
            }

            &-title {
                left: 0;
                padding: 10px 20px;
                font-weight: bold;
                border-top-right-radius: 15px;
                border-bottom-right-radius: 15px;
            }

            &-icon {
                right: 15px;
                padding: 10px 15px;
                border-radius: 50%;

                .fa-caret-right {
                    color: $black;

                    &:before {
                        font-size: 1.4rem;
                    }
                }
            }

            &-img {
                z-index: -1;
            }
        }
    }
}

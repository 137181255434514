.newsletter {
  &-box {
    @media(min-width: 768px) {
      max-width: 1200px;
    }
  }

  &-image {
    max-height: 300px;
    max-width: 300px;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.mail-icon {
  font-size: 200px;
  margin-left: -180px;
  opacity: 0.5;
  display: none !important;

  @media(min-width: 768px) {
    display: block !important;
  }
}

.discount-badge {
  font-size: 40px;
  font-weight: bold;
  margin-right: -120px;
  display: none;

  @media(min-width: 768px) {
    display: block;
  }
}


.listing {
  min-height: 500px;

  .product-tile, .promotion-tile, .facet-tile {
    max-width: 240px;
    width: 50%;
    margin: 0 0 35px 0;

    @media (min-width: 768px) {
      width: 33%;
    }

    @media (min-width: 1200px) {
      width: 25%;
    }

    &:nth-child(4n) {
      &:after {
        flex-basis: 100%;
        height: 0;
      }
    }
  }

  .promotion-tile {
    img {
      object-fit: contain;
    }
  }
}

.listing {
  &.wide {
    .product-tile {
      max-width: 289px;
    }
  }

  .product-tile {
    border-bottom: 1px solid rgba($black, 0.15);
    padding-bottom: 35px;
  }
}

.product-tile {
  //max-width: 210px;
  font-size: 0.88rem;
  position: relative;
  padding: 10px;
  border: 1px solid transparent;

  &:hover {
    @media (min-width: 768px) {
      border: 1px solid rgba($black, 0.15);
      border-radius: 10px;
    }
  }

  .tile-border {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: -1;

    &-left {
      &:first-child {
        display: none;
      }
    }
  }

  &:nth-child(even) {
    padding-right: 8px;
  }

  @media (min-width: 768px) {
    margin-right: 0;
  }

  .actions {
    margin: 15px 0 0 0;
    padding: 0 15px 0 0;
    position: relative;
    bottom: 0;
    width: 100%;

    @media (min-width: 768px) {
      padding: 0 15px 0 15px;
    }
  }

  .variant-container {
    height: 40px;
    margin: 0 0 15px 0;

    .swiper {
      height: 40px;
      z-index: 500;

      &-wrapper {
        z-index: 500;
      }
    }
  }

  .swiper-slide-active {
    > div {
      border-color: $primaryColor !important;
    }
  }

  .variant-selectors {
    padding: 0 15px;
  }

  &-text {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-break: break-word;
    overflow: hidden;
    hyphens: auto;
    text-align: left;

    &.multi-line {
      height: 36px;
      line-height: 1.18rem;
    }
  }

  &-title {
    height: 40px;
  }

  .pseudo-price {
    height: 20px;
  }

  &--detail {
    display: none;
    position: absolute;

    &.closed {
      display: none;
    }

    &.opened {
      display: block;
    }

    &-cart {
      position: absolute;
      bottom: 15px;
      width: 100%;

      .btn {
        width: 86%;
      }
    }

    &-close {
      margin-bottom: 20px;

      &:hover {
        cursor: pointer;

        i {
          color: $secondaryColorLight;
        }
      }

      i {

        &:before {
          font-size: 1.8rem;
        }
      }
    }
  }

  .image-container {
    width: 125px;
    height: 125px;

    @media (min-width: 768px) {
      width: 175px;
      height: 175px;
    }
  }
}

.bg-gold-glitter, .bg-dark, .bg-secondary {
  .product-tile {
    &:hover {
      border: 1px solid transparent;
    }
  }
}

.product-rating {

}

.rating-icons {
  .fa-solid {
    color: $primaryColor;
  }
}

.product-item {
  margin-left: 7px;
  margin-right: 7px;

  @media (min-width: 768px) {
    max-width: 200px;
  }

  a {
    display: block;
  }

  img {
    display: block;
    height: 120px;
    width: auto;
    margin: 0 auto 20px auto;
  }

  p {
    height: 30px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.variant-container {
  [role=button] {
    &:hover {
      //color: $secondaryColor;
    }
  }
}

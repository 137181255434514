.fa-green {
  path {
    color: $green;
  }
}

.fa-red {
  path {
    color: $red;
  }
}

.fa-orange {
  path {
    color: $orange;
  }
}
.inline-circle {
  border-radius: 50%;
  border: 1px solid $gray;
  padding: 5px 10px;
  margin-right: 10px;
  font-weight: bold;
}

.product-detail {
  .read-more {
    &.toggle-disabled {
      @media (min-width: 1200px) {
        height: auto;
        text-overflow: initial;
      }
    }

    &-toggles {
      &.toggle-disabled {
        @media (min-width: 1200px) {
          display: none;
        }
      }
    }
  }
}

.read-more {
  height: 200px;
  text-overflow: ellipsis;
  overflow: hidden;

  &.open {
    + * .read-more-toggles:before {
      content: unset;
    }
  }

  &-toggles {
    margin-top: 0;
    width: 100%;
    //text-align: center;
    position: relative;

    &.open {
      &:before {
        content: unset;
      }
    }

    &:hover {
      cursor: pointer;
    }

    &:before {
      position: absolute;
      bottom: 20px;
      left: 0;
      z-index: 50;
      width: 100%;
      height: 155px;
      background: #fff;
      background: linear-gradient(180deg, rgba(255,255,255,0) 0%, #fff 83%, #fff 100%, #fff 100%);
      content: "";
    }
  }

  &.open {
    + .read-more-toggles {
      &:before {
        position: relative !important;
      }
    }
  }

  //@media (min-width: 768px) {
  //  height: auto !important;
  //
  //  &-toggles {
  //    display: none;
  //  }
  //}
}

.buy-overlay, .notification-overlay {
  position: fixed;
  bottom: 0;
  background-color: $white;
  z-index: 6000;

  @media (min-width: 768px) {
    width: 1180px;
    top: 90px;
    bottom: unset;
    left: 50%;
    transform: translateX(-50%);
    display: none;
  }
}

.notification-overlay {
  top: 82px;
  position: absolute;
  height: 185px;
  display: none;

  @media (min-width: 768px) {
    top: 98px;
    height: auto;
  }
}

.cart-notification {
  position: relative;

  .fa-circle-xmark {
    position: absolute;
    z-index: 500;
    font-size: 1.5rem;
    top: 0;
    right: 0;

    @media (min-width: 768px) {
      top: -25px;
      right: -25px;
    }
  }
}

.pre-cart-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $white;
  z-index: 6500;
  -webkit-box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgba(0,0,0,0);
  box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgba(0,0,0,0);
  visibility: hidden;

  > div {
    height: auto;

    //@media (min-width: 768px) {
    //  height: 100%;
    //}
  }

  @media (min-width: 768px) {
    top: 150px;
    left: 50%;
    transform: translateX(-50%);
    width: 800px;
    height: 640px;
  }

  .position-relative > .fa-circle-xmark {
    position: absolute;
    right: 7px;
    top: 7px;
    font-size: 1.8rem;

    @media (min-width: 768px) {
      right: -25px;
      top: -25px;
    }

    &:hover {
      cursor: pointer;
    }
  }
}

.payment {
  &-overlay {
    height: 100%;
    width: 100%;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 1000;
    display: none;
  }

  &-container {
    width: 100%;
    height: 320px;
    padding: 1rem;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    background-color: $white;
    -webkit-box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgba(0,0,0,0);
    box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgba(0,0,0,0);
    z-index: 1010;
    border-radius: 10px;
    display: none;

    @media (min-width: 768px) {
      width: 450px;
    }
  }
}

.position-relative {
  > .fa-circle-xmark {
    position: absolute;
    right: -5px;
    top: -5px;
    font-size: 1.8rem;

    @media (min-width: 768px) {
      right: -25px;
      top: -25px;
    }
  }
}

.spinner-container {
    width: 200px;
    z-index: 100;

    img {
        width: 200px;
    }
}

.transparent {
    opacity: 0.3;
}

.content-height {
    min-height: 400px;

    @media (min-width: 768px) {
        min-height: 500px;
    }
}

.address-placeholder {
    min-height: 112px;
}

.confirm-sidebar {
    display: none;

    @media (min-width: 768px) {
        display: block;
    }

    &.confirm-route {
        display: block;
    }
}

.payment-modal {
    .modal-body {
        min-height: 300px;
    }
}

.blog-text{
  a {
    color: $primaryColor;
  }
}

.category-content, .blog-content {
  h1 {
    font-size: 1.25rem;
    line-height: 1.5rem;
    margin: 1.125rem 0 0.35rem 0;

    @media screen and (min-width: 768px) {
      font-size: 1.5rem;
      line-height: 2rem;
    }

    @media screen and (min-width: 1200px) {
      font-size: 1.875rem;
      line-height: 2.25rem;
    }

    * {
      font-size: 1.75rem;
      margin: 1.125rem 0 0.35rem 0;

      @media screen and (min-width: 768px) {
        font-size: 1.5rem;
        line-height: 2rem;
      }

      @media screen and (min-width: 1200px) {
        font-size: 1.875rem;
        line-height: 2.25rem;
      }
    }
  }

  h2 {
    font-size: 1.125rem;
    line-height: 1.5rem;
    margin: 2rem 0 .625rem 0;

    @media screen and (min-width: 768px) {
      font-size: 1.5rem;
      line-height: 1.75rem;
    }

    * {
      font-size: 1.125rem;
      line-height: 1.5rem;
      margin: 2rem 0 .625rem 0;

      @media screen and (min-width: 768px) {
        font-size: 1.5rem;
        line-height: 1.75rem;
      }
    }
  }

  h3 {
    font-size: 1.1rem;
    margin: 2rem 0 .625rem 0;

    @media screen and (min-width: 768px) {
      font-size: 1.3rem;
      line-height: 1.75rem;
    }

    * {
      font-size: 1.1rem;
      margin: 2rem 0 .625rem 0;

      @media screen and (min-width: 768px) {
        font-size: 1.3rem;
        line-height: 1.75rem;

      }
    }
  }

  h4 {
    font-size: 1rem;
    margin: 2.2rem 0 1rem 0;

    * {
      font-size: 1rem;
      margin: 2.2rem 0 1rem 0;
    }
  }

  h5 {
    font-size: 1.05rem;
    margin: 2rem 0 1rem 0;

    * {
      font-size: 1.05rem;
      margin: 2rem 0 1rem 0;
    }
  }

  .ql-align-center {
    text-align: center;
  }

  p {
    margin-bottom: 0.5rem;
  }
}

[datatype="content-text"] {
  h1, h2, h3, h4, h5 {
  }

  &.has-bg-color {
    h1, h2, h3, h4, h5 {
      margin: 1rem 0;
    }
  }
}

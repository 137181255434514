.filter {
  @media(min-width: 1200px) {
    width: 33.3%;
  }

  &-toggle {
    border: 1px solid $grayMid;
    border-radius: 4px;
  }

  &-overlay {
    display: none;
    top: 47px;
    left: 12px;
    height: auto;
    width: calc(200% - 24px);
    border: 1px solid $grayMid;
    background-color: $white;
    border-radius: 4px;
    z-index: 905;
    -webkit-box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgba(0,0,0,0);
    box-shadow: 0px 10px 13px -7px #000000, 5px 5px 15px 5px rgba(0,0,0,0);
  }

  &[data-filter-index="3"] {
    .filter {
      &-overlay {
        right: 12px;
        left: unset;
      }
    }
  }

  &-options {
    width: 100%;
    margin-left: 5px;
    padding-top: 85px;

    @media (min-width: 768px) {
      padding-top: 0;
    }

    @media (min-width: 1200px) {
      height: 350px;
      margin: 0;
      padding: 0;
    }
  }

  &.active {
    .filter {
      &-toggle {
        border-bottom: none;

        > span {
          font-weight: bold;
        }

        &:after {
          position: absolute;
          left: -1px;
          top: 90%;
          height: 14px;
          content: "";
          background-color: $white;
          width: calc(100% + 2px);
          border-left: 1px solid $grayMid;
          border-right: 1px solid $grayMid;
          z-index: 906;
        }
      }

      &-overlay {
        border-top-left-radius: 0;
      }
    }
  }

  &-list {
    &-selected {
      overflow-y: auto;
    }
  }

  &-detail {
    &-list {
      overflow-y: scroll;
      overflow-x: hidden;
      height: 100%;
      padding: 130px 0 80px 0;
      flex-grow: 1;
    }

    &-container {
      display: flex;
      flex: 1 0 auto;
      flex-direction: column;
      width: 100%;
      z-index: 1000;
    }
  }

  &-option {
    padding: 200px 0 80px 0;

    &-search {
      position: fixed;
      width: 90%;
      background-color: $white;
      z-index: 1000;

      @media (min-width: 1200px) {
        position: relative;
        width: 100%;
      }
    }
  }
}

.btn-filter {
  text-wrap: avoid;
  word-break: keep-all;
  white-space: nowrap;
}

.ui-slider-horizontal {
  .ui-slider-handle {
    border-radius: 50%;
    background: none;
    background-color: $secondaryColor;

    &:focus-visible, &:hover {
      outline: none;
      border: 1px solid $grayMid;
    }
  }
}

.ui-slider-range {
  background: none;
  background-color: $secondaryColorLight;
}

.ui-widget {
  &.ui-slider {
    border-top: 1px solid $grayMid;
    border-bottom: 1px solid $grayMid;
    border-left: none;
    border-right: none;
    border-radius: 0;
  }
}
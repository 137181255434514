.mui-autocomplete-input {
  .MuiInputBase-root {
    overflow: hidden;
    height: 50px !important;
  }
}

.MuiPickersDay-root, .MuiPickersYear-root {
  &.Mui-selected {
    color: $white !important;
  }
}

.MuiPickersYear-root {
  .Mui-selected {
    color: $white !important;
  }
}
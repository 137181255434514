.search {
    &-result {
        position: absolute;
        top: 0;
        width: 100%;
        background: $white;
        z-index: 1000;
    }

    &-loading {
        height: 300px;
    }

    &-spinner {
        margin-top: 4px;

        @media (min-width: 768px) {
            margin-top: -3px;
        }
    }

    &-clear, &-magnifying {
        margin-top: 8px;
    }

    &-input {
        font-size: 16px;
        border-color: $primaryColor;

        @media (min-width: 768px) {
            font-size: unset;
        }

        &:focus {
            border-color: $primaryColor;
        }
    }
}

.promo {
  background-color: $primaryColor;
}

.usp {
  //background-color: $secondaryColor;
  background-color: $black;
  color: $white;
}

.logo {
  @media (min-width: 768px) {
    max-width: 240px;
  }
}

.header {
  position: fixed;
  top: 0;
  z-index: 950;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  background-color: $white;
  transition: top 450ms cubic-bezier(0.17, 0.04, 0.03, 0.94);

  &.show {
    top: 0;
  }

  &.hide {
    top: -250px;
  }

  &.checkout {
    margin-top: 0;
  }

  &.sticky {
    margin-top: 0;
    position: fixed;
    -webkit-box-shadow: -10px 0px 13px -7px #000000, 10px 0px 13px -7px #000000, 5px 5px 15px 5px rgba(0,0,0,0);
    box-shadow: -10px 0px 13px -7px #000000, 10px 0px 13px -7px #000000, 5px 5px 15px 5px rgba(0,0,0,0);
  }

  &-icons {
    .fa-solid:before {
      font-size: 1.6rem;
    }
  }
}
.fancybox {
  &__backdrop {
    background: #fff;
  }

  &__thumbs {
    .carousel__slide {
      .fancybox__thumb {
        &:after {
          border: 2px solid rgba(79, 79, 79, 0.7);
          border-radius: 10px;
          margin-right: 10px;
          padding: 15px 7px;
        }
      }
    }
  }

  &__container {
    z-index: 7000;
  }
}

[data-fancybox] {
  .preview {
    width: 240px;

    @media (min-width: 768px) {
      width: 280px;
    }

    @media (min-width: 992px) {
      width: 350px;
    }

    @media (min-width: 1200px) {
      width: 420px;
    }
  }

  .thumbnail {
    width: 60px;

    @media (min-width: 768px) {
      width: 80px;
    }
  }
}
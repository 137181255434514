.detail-image-container {
  width: 150px;
  height: 150px;

  @media(min-width: 768px) {
    width: 400px;
    height: 400px;
  }
}

.delivery {
  &-status {
    &-icon {
      &:before {
        font-size: 9px;
      }
    }
  }
}

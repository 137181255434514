.discount {
  &-bubble {
    border-radius: 50%;
    background-color: $black;
    color: $white;

    &.big {
      height: 60px;
      width: 60px;

      @media (min-width: 768px) {
        height: 120px;
        width: 120px;
      }

      div {
        height: 60px;
        width: 60px;
        line-height: 60px;

        @media (min-width: 768px) {
          height: 120px;
          width: 120px;
          line-height: 120px;
        }
      }
    }

    div {
      text-align: center;

      .fa-solid {
        font-size: 1.4rem;

        @media (min-width: 768px) {
          font-size: 2.2rem;
        }
      }
    }
  }
}
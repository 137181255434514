.promo-slider {
  @media (min-width: 768px) {
    //height: 398px;
    max-height: 394px;
  }

  .swiper-slide {
    width: 100%;
  }
}

.product-slider, .account-slider {
  height: auto;

  //@media (min-width: 768px) {
  //    height: 495px;
  //}
}

.product-slider {
  .product-tile {
    width: 100%;
    max-width: unset;
  }
}

.cart-product-slider {
  height: 355px;
}

.offcanvas-cart {
  .cart-product-slider {
    height: 355px;
  }
}

.brand-slider {

  a {
    height: 100%;
    padding: 12px 7px;
    text-align: center;
    border: 1px solid transparent;

    &:hover {
      @media (min-width: 992px) {
        border: 1px solid rgba($black, 0.1);
        border-radius: 10px;
      }
    }
  }

  img {
    width: 80% !important;
    margin: 0 auto;
  }

  .swiper-button {
    &-next, &-prev {
      top: 32%;
    }
  }
}

.slick-slide {
  height: auto;

  img {
    object-fit: cover;

    @media (min-width: 768px) {
      width: 100%;
    }
  }
}

[data-slider="slick"] {
  > .product-tile {
    display: none;
  }

  > img {
    display: none;
  }

  > a {
    > img {
      display: none;
    }
  }
}

.swiper {
  &-pagination {
    bottom: 0 !important;

    &-bullet {
      &-active {
        background: $black;
      }

      border: 1px solid $black;
      background: $white;
    }
  }

  &-slide {
    &-active {
      .border {
        &:hover {
          border-color: $primaryColor !important;
        }
      }
    }
  }

  &-button {
    &-next, &-prev {
      color: $black;

      &:after {
        font-size: 25px !important;
      }
    }

    &-next {
      right: 0;
      background-color: rgba($white, 0.4);
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
      padding: 30px 5px 30px 10px
    }

    &-prev {
      left: 0;
      background-color: rgba($white, 0.4);
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
      padding: 30px 10px 30px 5px;
    }
  }
}

.emotion {
  &-grid {
    > [class^=col-] {
      > .product-tile {
        width: 100%;
        max-width: 100%;
      }
    }
  }
}

.blog-filter-slider {
  .swiper-wrapper {
    padding: 0 15px;
    width: calc(100% - (2 * 35px));
    margin: 0 auto;
  }

  .swiper-button-prev, .swiper-button-next {
    top: 22px;
    border: 1px solid $black;
    border-radius: 7px;
    padding: 0 15px;
    background-color: $black;
    color: $white;
    height: 40px;

    &:after {
      font-size: 18px !important;
    }
  }

  .swiper-button-prev {
    left: 0;
  }
}

.hero-tile-slider {
  --swiper-navigation-size: 100px;
  --swiper-navigation-top-offset: calc(var(--hero-tile-height) * 0.5);

  --hero-tile-width: calc(100vw - 24px);
  --hero-tile-height: calc(var(--hero-tile-width));

  --swiper-pagination-bottom: auto;
  --swiper-pagination-top: calc(var(--hero-tile-height) + 8px);
  --swiper-pagination-bullet-size: 12px;

  @media (min-width: 576px) {
    --hero-tile-width: 516px;
  }
  @media (min-width: 768px) {
    --hero-tile-width: 696px;
    --hero-tile-height: calc(var(--hero-tile-width) * (396 / 1156));
  }
  @media (min-width: 992px) {
    --hero-tile-width: 936px;
  }
  @media (min-width: 1200px) {
    --hero-tile-width: 1116px;
  }
  @media (min-width: 1400px) {
    --hero-tile-width: 1156px;
  }

  .swiper-button {
    &-prev, &-next {
      &::after {
        font-size: 40px!important;
      }
    }
  }

  .swiper-pagination {
    // override other !important with more specific selector
    bottom: auto!important;
  }
}

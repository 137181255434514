.animation-pulse {
  transform: scale(1);
  animation: pulse;
  animation-duration: 2s;
  //animation-iteration-count: 4;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.15);
  }

  100% {
    transform: scale(1);
  }
}
.image-grid {
  position: relative;
  /*line-height: 0;*/
}

.image-grid .article-list {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: white;
  display: grid;
  grid-auto-flow: dense;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 5px;
  mix-blend-mode: screen;
}

.image-grid .article-list-with-content {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: grid;
  grid-auto-flow: dense;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 5px;
}

.image-grid .article-list .article-list-item {
  background: black;
  height: 100%;
}

.image-grid .article-list-item {
  position: relative;
  overflow: hidden;
}

.image-grid .icon--cross {
  position: absolute;
  right: 0;
  top: 0;
  margin: 25px;
  font-size: 18px;
  color: #fff;
  text-shadow: #000 1px 1px 5px;
  z-index: 1600;
  cursor: pointer;
}
@media (max-width: 768px) {
  .image-grid .icon--cross {
    margin: 20px;
  }
}
.image-grid.image-grid-theme-dark .icon--cross {
  color: #000;
  text-shadow: #fff 1px 1px 5px;
}



.image-grid .list-item-number {
  position: absolute;
  left: 0;
  top: 0;
  margin: 25px;
  font-size: 18px;
  color: #fff;
  text-shadow: #000 1px 1px 5px;
}
@media (max-width: 768px) {
  .image-grid .list-item-number {
    margin: 20px;
  }
}
.image-grid.image-grid-theme-dark .list-item-number {
  color: #000;
  text-shadow: #fff 1px 1px 5px;
}


.article-list-mobile-content {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: none;
}
.article-list-mobile-content.active {
  display: block;
}
.article-list-mobile-content .article-list-item {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: none;
}
.article-list-mobile-content .article-list-item.show {
  display: block;
}

.image-grid .full-link {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 500;
  display: none;
}

.image-grid .article-list-item .list-item-text,
.image-grid .article-list-item.active .list-item-text-inactive {
  display: none;
}

.image-grid .article-list-item.active .list-item-text,
.image-grid .article-list-item .list-item-text-inactive,
.image-grid .article-list-item.active .full-link {
  display: block;
}

.grid-card{
  position: relative;
  transform-style: preserve-3d;
  transition: 0.4s ease;
  cursor: pointer;
  width: 100%;
  height: 100%;
  color: #fff;
}

.grid-card-front , .grid-card-back{
  position: absolute;
  top:0;
  left: 0;
  width: 100%;
  height: 100%;
}

.grid-card-front{
  font-size: 40px;
  justify-content: center;
  text-align: center;
  display: flex;
  align-items: center;
}

.grid-card-back{
  background-color: #258193 ;
  font-size: 25px;
  justify-content: center;
  text-align: center;
  display: flex;
  align-items: center;
  backface-visibility: hidden;
}


.image-grid .article-list-mobile-content .article-list-item.show .grid-card,
.image-grid .article-list-with-content .article-list-item:hover .grid-card {
  transform: rotateY(180deg);
}

.image-grid .article-list-mobile-content .article-list-item.show .grid-card-front,
.image-grid .article-list-with-content .article-list-item:hover .grid-card-front {
  background-color: #fff;
}

.grid-card-back{
  transform: rotateY(180deg);
}


.image-grid .columns-count-x-1 { grid-template-columns: repeat(1, 1fr); }
.image-grid .columns-count-x-2 { grid-template-columns: repeat(2, 1fr); }
.image-grid .columns-count-x-3 { grid-template-columns: repeat(3, 1fr); }
.image-grid .columns-count-x-4 { grid-template-columns: repeat(4, 1fr); }
.image-grid .columns-count-x-5 { grid-template-columns: repeat(5, 1fr); }
.image-grid .columns-count-x-6 { grid-template-columns: repeat(6, 1fr); }
.image-grid .columns-count-x-7 { grid-template-columns: repeat(7, 1fr); }
.image-grid .columns-count-x-8 { grid-template-columns: repeat(8, 1fr); }
.image-grid .columns-count-x-9 { grid-template-columns: repeat(9, 1fr); }
.image-grid .columns-count-x-10 { grid-template-columns: repeat(10, 1fr); }
.image-grid .columns-count-x-11 { grid-template-columns: repeat(11, 1fr); }
.image-grid .columns-count-x-12 { grid-template-columns: repeat(12, 1fr); }
.image-grid .columns-count-x-13 { grid-template-columns: repeat(13, 1fr); }
.image-grid .columns-count-x-14 { grid-template-columns: repeat(14, 1fr); }
.image-grid .columns-count-x-15 { grid-template-columns: repeat(15, 1fr); }

@media (min-width: 768px) {
  .image-grid .columns-count-x-sm-1 { grid-template-columns: repeat(1, 1fr); }
  .image-grid .columns-count-x-sm-2 { grid-template-columns: repeat(2, 1fr); }
  .image-grid .columns-count-x-sm-3 { grid-template-columns: repeat(3, 1fr); }
  .image-grid .columns-count-x-sm-4 { grid-template-columns: repeat(4, 1fr); }
  .image-grid .columns-count-x-sm-5 { grid-template-columns: repeat(5, 1fr); }
  .image-grid .columns-count-x-sm-6 { grid-template-columns: repeat(6, 1fr); }
  .image-grid .columns-count-x-sm-7 { grid-template-columns: repeat(7, 1fr); }
  .image-grid .columns-count-x-sm-8 { grid-template-columns: repeat(8, 1fr); }
  .image-grid .columns-count-x-sm-9 { grid-template-columns: repeat(9, 1fr); }
  .image-grid .columns-count-x-sm-10 { grid-template-columns: repeat(10, 1fr); }
  .image-grid .columns-count-x-sm-11 { grid-template-columns: repeat(11, 1fr); }
  .image-grid .columns-count-x-sm-12 { grid-template-columns: repeat(12, 1fr); }
  .image-grid .columns-count-x-sm-13 { grid-template-columns: repeat(13, 1fr); }
  .image-grid .columns-count-x-sm-14 { grid-template-columns: repeat(14, 1fr); }
  .image-grid .columns-count-x-sm-15 { grid-template-columns: repeat(15, 1fr); }
}

@media (min-width: 992px) {
  .image-grid .columns-count-x-md-1 { grid-template-columns: repeat(1, 1fr); }
  .image-grid .columns-count-x-md-2 { grid-template-columns: repeat(2, 1fr); }
  .image-grid .columns-count-x-md-3 { grid-template-columns: repeat(3, 1fr); }
  .image-grid .columns-count-x-md-4 { grid-template-columns: repeat(4, 1fr); }
  .image-grid .columns-count-x-md-5 { grid-template-columns: repeat(5, 1fr); }
  .image-grid .columns-count-x-md-6 { grid-template-columns: repeat(6, 1fr); }
  .image-grid .columns-count-x-md-7 { grid-template-columns: repeat(7, 1fr); }
  .image-grid .columns-count-x-md-8 { grid-template-columns: repeat(8, 1fr); }
  .image-grid .columns-count-x-md-9 { grid-template-columns: repeat(9, 1fr); }
  .image-grid .columns-count-x-md-10 { grid-template-columns: repeat(10, 1fr); }
  .image-grid .columns-count-x-md-11 { grid-template-columns: repeat(11, 1fr); }
  .image-grid .columns-count-x-md-12 { grid-template-columns: repeat(12, 1fr); }
  .image-grid .columns-count-x-md-13 { grid-template-columns: repeat(13, 1fr); }
  .image-grid .columns-count-x-md-14 { grid-template-columns: repeat(14, 1fr); }
  .image-grid .columns-count-x-md-15 { grid-template-columns: repeat(15, 1fr); }
}

.grid-2-card {
  position: relative;
  width: 100%;
  height: 100%;
}
.grid-2-card::before, .grid-2-card::after {
  content: "";
  position: absolute;
  top: 1.25em;
  bottom: 1.25em;
  left: 1.25em;
  right: 1.25em;
  cursor: pointer;
}
.grid-2-card::before {
  transform: scale(0, 1);
  transition: transform ease-out 250ms;
  border-top: 1px solid #F0F5FB;
  border-bottom: 1px solid #F0F5FB;
}
.grid-2-card::after {
  transform: scale(1, 0);
  transition: transform ease-out 250ms;
  border-left: 1px solid #F0F5FB;
  border-right: 1px solid #F0F5FB;
}
.grid-2-card.active::before,
.grid-2-card:hover::before {
  transform: scale(1.05, 1);
}
.grid-2-card.active::after,
.grid-2-card:hover::after {
  transform: scale(1, 1.05);
}


.grid-2-card.active,
.grid-2-card:hover {
  background: #258193;
}

.grid-2-card.active .grid-2-card__text,
.grid-2-card:hover .grid-2-card__text {
  opacity: 1;
}

.grid-2-card__text p {
  color: #F0F5FB;
}

.grid-2-card__text {
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: opacity ease-out 250ms;
}


.btn-image-grid {
  color: #fff;
  background-color: #dbb489 !important;
  margin-bottom: 0.5rem;
}

.close-image-grid-card {
  position: absolute;
  right: 0;
  top: 0;
  padding: 1.5rem;
  z-index: 550;
}



